import React, { useContext, ReactNode, useEffect } from 'react'

import { BasketContext, BasketContextType, BasketItem } from '../../contexts/Basket'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { QuantityInput } from 'components/inputs/QuantityInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useProductTypes } from 'hooks/useProductTypes'

const BasketBase = ({basketContext, children, isReadOnly}: {basketContext: BasketContextType, children?: ReactNode, isReadOnly: boolean}) => {
  
    const { basketItems, clearBasket, addToBasket, removeFromBasket, clearProductFromBasket} = basketContext

    const { getProductTypes, getProductType } = useProductTypes()

    useEffect(() => {      
      getProductTypes()
    }, [])
  
    const Styles = {
      borderStyle: '1px solid rgb(228, 228, 228)',
      headerBgColor: '#fafafa'
    }
    
    return(
      <div className="columns p-4">
        <div className="column">
      
          <div className="columns pb-4 mb-4" style={{borderBottom: Styles.borderStyle}}>
            <div className="column">
              {!isReadOnly && <h1 className="subtitle is-4">Basket</h1> }
            </div>
            <div className="column">
              <h1 className="subtitle is-4 is-pulled-right">{basketItems.length === 1 ? `${basketItems.length} product` : `${basketItems.length} products`}</h1>
            </div>
          </div>
  
          {basketItems.length > 0
          ?
          <>
            <div className="columns mt-5">
              <div className="column">
  
              <div className="columns is-vcentered" style={{backgroundColor: Styles.headerBgColor}}>
                <div className="column is-5">   
                    <ColumnLabel label={`Item`} />
                </div>
                <div className="column is-4">
                    <ColumnLabel label={`Quantity`} />
                </div>
                <div className="column is-2">
                    <ColumnLabel label={`Weight`} />
                </div>
              </div>
  
  
                {basketItems.map((item, idx) => (
                    <div className="columns pt-1 pb-1" style={{borderBottom: Styles.borderStyle}} key={`basket-item=-${idx}`}>
  
                      <div className="column is-5">
                        <div className="columns">
                          <div className="column is-narrow">
                            <FontAwesomeIcon icon={faFile} size='2x' />        
                          </div>
                          <div className="column">
                              <p>
                              <span className="has-text-weight-semibold title is-6" >
                                  {item.product.productCode}
                              </span>
                              </p>
                              <p className="subtitle is-7"><span className="has-tooltip-arrow has-tooltip-multiline" data-tooltip={item.product.description}>{item.product.description.length > 40 ? `${item.product.description.substring(0,25)}...` : item.product.description}</span></p> 
                          </div>
                        </div>
                      </div>
  
                      <div className="column is-4">
                      {!isReadOnly
                      ?
                        <QuantityInput 
                          value={item.quantity} 
                          onIncrement={() => addToBasket({ product: item.product, quantity: 1 })} 
                          onDecrement={() => removeFromBasket(item.product.objectID)}  />
                      :
                        <p>x {item.quantity}</p>
                      }
                      </div>
  
                      <div className="column is-2">
                        {`${getProductType(item.product.productType)?.weight?.value}kg`}
                      </div>

                      {!isReadOnly &&
                        <div className="column is-narrow">
                          <FontAwesomeIcon className="is-clickable" onClick={() => clearProductFromBasket(item.product.objectID)} icon={faXmark} />
                        </div>
                      }
  
                  </div>
                ))}
  
                <div className="columns pt-1 pb-1" style={{borderBottom: Styles.borderStyle}}>
  
                  <div className="column is-5">
                    <div className="columns">
                      <div className="column">
                          <p>
                          <span className="has-text-weight-semibold title is-6">
                              Total
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
  
                  <div className="column is-4">
                    <span className="has-text-weight-semibold title is-6">
                      {basketItems.reduce((total, item) => (total + item.quantity), 0)} items
                    </span>
                  </div>
  
                  <div className="column is-2">
                    <span className="has-text-weight-semibold title is-6">
                      {`${basketItems.reduce((total, item) => (total + (getProductType(item.product.productType)?.weight?.value || 0) * item.quantity), 0)}kg`}
                    </span>
                  </div>
  
                </div>
  
              </div>
            </div>
  
            <div className="columns pt-4">
              <div className="column">
                {children}
              </div>
            </div>
          </>
          :
          <p className='is-size-6'>Your basket is currently empty</p>
          }
  
        </div>
    </div>
    )
  }

const Basket = ({children} : {children?: ReactNode}) => {

    const basketContext: BasketContextType = useContext(BasketContext)

    return <BasketBase basketContext={basketContext} isReadOnly={false}>{children}</BasketBase>
}

const ReadOnlyBasket = ({ basketItems }: {basketItems: BasketItem[] }) => {
  
  return <BasketBase
    basketContext={{
      basketItems: basketItems,
      getBasketItem: null,
      addToBasket: null,
      removeFromBasket: null,
      clearProductFromBasket: null,
      clearBasket: null,
    }}
    isReadOnly={true} />
} 
  
  export { Basket, ReadOnlyBasket }