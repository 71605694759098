import { useAuth0 } from '@auth0/auth0-react'
import { faClone, faDollarSign, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from 'ManageUsersScreen'
import { toast } from 'bulma-toast'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { API_URL, DATE_FORMAT, DATE_TIME_FORMAT, SHORT_DATE_FORMAT, prettyDisplayValue } from 'constants/Constants'
import { ICustomerDetails, IDeliveryDetails, IOrderDetails, OrderStatus } from 'hooks/useOrder'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface IOrderOverviewResponse {
    orderId: string,
    orderDetails: IOrderDetails
    customerDetails: ICustomerDetails,
    deliveryDetails: IDeliveryDetails,
    noOfProducts: number
}

interface IOrdersResponse {
    orders: IOrderOverviewResponse[],
    cursor?: string
}

const ManageOrdersTableHeaders = () => {

    return (
        <div className="columns is-vcentered" style={{backgroundColor: '#fafafa'}}>
            <div className="column is-3">   
                <ColumnLabel label={`Order Details`} />     
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Customer`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Order Recipient`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Order Status`} />
            </div>
            <div className="column is-2">
                <ColumnLabel label={`Products`} />
            </div>
            <div className="column is-1">
                {/* Leave space for actions button. */}
            </div>
        </div>
    )
}

type PaginationState = {
    cursorPointer: number,
    currentPage: number,
    hasNext: boolean
}

type OrderBy = 'newest' | 'oldest'

const ManageOrdersScreen = ({ loggedInUser }:{ loggedInUser: User }) => {

    const [ordersResp, setOrdersResp] = useState<IOrdersResponse>(null)
    const [isGetOrdersRequestLoading, setIsGetOrdersRequestLoading] = useState<boolean>(false)
    const [paginationState, setPaginationState] = useState<PaginationState>(null)
    const [pageCursors, setPageCursors] = useState<string[]>([])
    const [orderBy, setOrderBy] = useState<OrderBy>('newest')
    const [statusFilter, setStatusFilter] = useState<OrderStatus | ''>('')

    const { getAccessTokenSilently  } = useAuth0()

    const loadOrders = async () => {

        const ordersResp: IOrdersResponse = await fetchOrders(orderBy, statusFilter)
        setOrdersResp(ordersResp)

        setPaginationState({
            currentPage: 0,
            cursorPointer: -1,
            hasNext: ordersResp.cursor ? true : false    
        })

        setPageCursors([ordersResp.cursor])    
    }

    const fetchOrders = async (orderby: OrderBy, orderStatus?: string, cursor?: string): Promise<IOrdersResponse> => {

        const accessToken: string = await getAccessTokenSilently()
        const cursorParam: string = cursor ? `&cursor=${cursor}` : ``

        const orderByParam: string = orderby ? `orderBy=${orderby}` : ``
        const ststusFilterParam: string = orderStatus ? `&orderStatus=${orderStatus}` : ``

        setIsGetOrdersRequestLoading(true)

        const response = await fetch(
            `${API_URL}/orders?${orderByParam}${ststusFilterParam}${cursorParam}`
                , {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                },
                }
            )

        setIsGetOrdersRequestLoading(false)

        const ordersResp: IOrdersResponse = await response.json()
        return ordersResp    
    }

    const getOrdersPage = async (cursorPointer: number, newPage: number, action: 'next' | 'previous') => {

        const cursor = pageCursors[cursorPointer]

        const ordersResp: IOrdersResponse = await fetchOrders(orderBy, statusFilter, cursor)
        setOrdersResp(ordersResp)

        if (ordersResp.cursor) {
            const doesAlreadyExist: boolean = pageCursors.includes(ordersResp.cursor)
            if (!doesAlreadyExist) {
                setPageCursors([...pageCursors, ordersResp.cursor])
            }
            setPaginationState({
                currentPage: newPage,
                cursorPointer: action === 'next' ? cursorPointer : cursorPointer - 1 < 0 ? 0 : cursorPointer,
                hasNext: true    
            })

        } else {
            setPaginationState({
                cursorPointer: cursorPointer,
                currentPage: newPage,
                hasNext: false    
            })

            setPageCursors([...pageCursors, undefined])
        }

    }

    useEffect(() => {

        loadOrders()
    }, [orderBy, statusFilter])


    const onCopyOrderId = (orderId: string) => {
        navigator.clipboard.writeText(orderId)

        toast({
            message: `Copied to clipboard`,
            type: 'is-info',
            position: 'bottom-right',
          })
    }

    const onClickPagePrevious = (e: React.MouseEvent) => {
        e.preventDefault();

        if (paginationState.currentPage - 1 > 0) {
            getOrdersPage(paginationState.cursorPointer -1, paginationState.currentPage - 1, 'previous')
        } else {
            loadOrders()
        }
    }

    const onClickPageNext = (e: React.MouseEvent) => {
        e.preventDefault();

        getOrdersPage(paginationState.cursorPointer + 1, paginationState.currentPage + 1, 'next')
    }

    if (isGetOrdersRequestLoading) return(
        <div className="container is-fullheight">
            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{padding: '5rem 4rem 5rem 4rem'}}>
                    <PageLoadSpinner />
                </div>
            </div>
        </div>
    )

    return(
        <div className="container is-fullheight">
            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{padding: '5rem 4rem 5rem 4rem'}}>

                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-3 mb-6">Orders</h1>
                        </div>
                    </div>

                    {(ordersResp && ordersResp.orders.length > 0) || (ordersResp && statusFilter !== '')
                        ?
                        <div className="block">

                            <div className="columns">
                                <div className="column is-narrow">

                                    <div className="field is-grouped is-vcentered pb-3">
                                        <div className="control select is-rounded">
                                                
                                            <select name="orderBy" style={{...(orderBy && {border: 'hsl(204, 86%, 53%) 1px solid'})}} value={orderBy} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setOrderBy(e.target.value as OrderBy)}>
                                                <option value={undefined} disabled={true} >Order By</option>
                                                <option value="newest">Newest</option>
                                                <option value="oldest">Oldest</option>
                                            </select>

                                        </div>

                                        <div className="control select is-rounded">

                                            <select name="status" style={{...(statusFilter && {border: 'hsl(204, 86%, 53%) 1px solid'})}} value={statusFilter} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStatusFilter(e.target.value as OrderStatus)}>
                                                <option value="" disabled={true} >Filter by order status</option>
                                                <option value="live">Live</option>
                                                <option value="on_hold">On Hold</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="complete">Complete</option>
                                            </select>

                                        </div>

                                    </div>  

                                </div>  

                                {statusFilter &&
                                    <div className="column is-narrow pl-0">
                                        <button className="button is-text" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => setStatusFilter('')}>
                                        <span>Clear <FontAwesomeIcon className='pl-2' icon={faTimes} /></span>
                                        </button>
                                    </div>
                                }
                            </div>
                            
                            {ordersResp.orders.length > 0 
                            ?
                            <>
                            <ManageOrdersTableHeaders />

                            {ordersResp.orders.map((o, idx) => (
                                <div className="columns is-vcentered" key={`user-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                                <div className="column is-3">
                                    <div className="columns is-vcentered">
                                        <div className="column is-narrow">
                                            <div className="avatar-circle">
                                                <FontAwesomeIcon icon={faDollarSign} size="lg" className="initials"/>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="mb-1 has-text-weight-medium" style={{fontSize: '0.95rem'}}>
                                                <span style={{wordBreak: 'break-word'}}>#{o.orderId}</span>
                                                <span className="icon is-small is-right has-text-dark is-clickable pl-3" onClick={() => onCopyOrderId(o.orderId)}>
                                                    <FontAwesomeIcon icon={faClone} />
                                                </span>  
                                            </div>
                                            <p>{moment(o.orderDetails.timestamp, DATE_TIME_FORMAT).format(DATE_FORMAT)}</p>
                                        </div>
                                    </div>
                                </div>  

                                <div className="column is-2">
                                    {o.customerDetails.merchantName}
                                </div>

                                <div className="column is-2">
                                    {o.deliveryDetails.companyName}
                                </div> 

                                <div className="column is-2">
                                    <span className={`tag is-medium
                                        ${o.orderDetails.status === 'live' ? 'is-info'
                                        : o.orderDetails.status === 'on_hold' ? 'is-warning'
                                        : o.orderDetails.status === 'cancelled' ? 'is-danger'
                                        : o.orderDetails.status === 'complete' ? 'is-primary'
                                        : ''}
                                    `}>{prettyDisplayValue(o.orderDetails.status)}</span>
                                </div> 

                                <div className="column is-2">
                                    {o.noOfProducts === 1 ? `1 product` : `${o.noOfProducts} products`}
                                </div>
                                <div className="column is-narrow">
                                <Link to={`/manage/orders/${o.orderId}`}><button className="button is-light">View</button></Link>
                                </div>
                            </div>
                            ))}                    

                            <nav className="pagination" role="navigation" aria-label="pagination">
                                <button disabled={paginationState.currentPage === 0} onClick={onClickPagePrevious} className={`button ${paginationState.currentPage === 0 ? `is-disabled` : ``} pagination-previous`}>Previous</button>
                                <button disabled={!paginationState.hasNext} onClick={onClickPageNext} className={`button ${!paginationState.hasNext ? `is-disabled` : ``} pagination-next`}>Next page</button>
                            </nav>
                        </>
                        :
                        <p>{`No ${prettyDisplayValue(statusFilter).toLowerCase()} orders found.`}</p>
                        }

                        </div>
                        
                        :
                        <p>You haven't received any orders yet</p>
                    }


                </div>
            </div>
        </div>
    )
}

export { ManageOrdersScreen }