import { useAuth0 } from '@auth0/auth0-react'
import { faCalendarXmark } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown, faArrowLeft, faArrowRight, faBackward, faBarcode, faCalendarDays, faCheck, faChevronLeft, faChevronRight, faEdit, faEllipsisVertical, faExclamation, faEye, faPlus, faRightLeft, faScissors, faTable, faTrash, faUser, faWeight, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormInputError } from 'IndexProductScreen'
import { User } from 'ManageUsersScreen'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { FormModal } from 'components/modals/FormModal'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ManageWorkshopUnscheduledWorkScreen } from './ManageWorkshopUnscheduledWorkScreen'
import InputField from 'components/inputs/InputField'

export const EditWorkModal = ({
    isModalActive, 
    onCancelModal,
    displayStatus = true
}: {
    isModalActive: boolean, 
    onCancelModal: (e: React.MouseEvent) => void,
    displayStatus?: boolean
 }) => {
    return (
        <FormModal
        isModalActive={isModalActive}
        modalTitle={`Edit Work`}
        onCancelModal={onCancelModal}
        Form={
            <form>
                {displayStatus &&
                    <div className="field pb-3">
                        <label className="label">Status</label> 
                        <div className="select">
                            <select name="role" value={`CUT`} onChange={() => {}}>
                                <option value="" disabled={true} >Select a status</option>
                                <option value="NOT_STARTED">Not Started</option>
                                <option value="CUT">Cut</option>
                                <option value="RETURNED_FROM_GALV">Returned From Galv</option>
                                <option value="FINAL_CHECKS_COMPLETE">Final Checks Complete</option>
                            </select>
                        </div>
                    </div>
                }

                <div className="columns">
                    <div className="column is-half">

                        <div className="field pb-3">
                            <label className="label">Assign To</label> 
                            <div className="control is-expanded has-icons-left">
                                <div className="select is-fullwidth">
                                    <select name="assignee" value={`Danny Halls`} onChange={() => {}}>
                                        <option value="" disabled={true} >Select a user </option>
                                        <option value="">Danny Halls</option>
                                        <option value="">Oscar Biggadike</option>
                                        <option value="">William Hallowell</option>
                
                                    </select>
                                </div>
                                <div className="icon is-small is-left">
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                            </div>
                            {/* {addUserFormErrorState && addUserFormErrorState.role.isError && 
                                <span className="help is-danger pb-1">{addUserFormErrorState.role.errorMessage}</span>
                            } */}
                        </div>

                    </div>
                    <div className="column is-half">

                        <div className="pb-2">
                            <label className="label">
                                <span>Schedule Date</span>
                            </label>
                            <div className={`field mb-0 has-addons`}>
                                <div className={`control`}>
                                    <input style={{width: '265px'}} className={`input is-fullwidth`} type={`date`} name={`scheduledDate`} value={new Date().toISOString().substring(0,10)} onChange={() => {}} />
                                    {/* <p className="help">{helpText}</p> */}
                                </div>                            
                            </div>
                            {/* { isShowErrorValue && <p className={`help is-danger`}>{ errorValue && errorValue.isError && errorValue.errorMessage }</p> } */}
                        </div>

                    </div>
                </div>

                <div className="field pb-3">
                    <label className="label">Job Type</label> 
                    <input onChange={() => {}} className="is-checkradio" id="galvJobType" type="radio" value="galv" name="jobType" checked={'galv' === 'galv'} />
                    <label htmlFor="galvJobType">Galv</label>
                    <input onChange={() => {}} className="is-checkradio" id="nonGalvJobType" type="radio" value="nonGalv" name="jobType" checked={false} />
                    <label htmlFor="nonGalvJobType">Non-Galv</label>
                </div>
                
                <InputField
                    fieldLabel={`Item Code`}
                    fieldName={'itemCode'}
                    onChange={() => {}}
                    value={`J38484-1A`}
                    errorValue={null}
                    helpText={``} />

                <InputField
                    fieldLabel={`Grating Code`}
                    fieldName={'gratingCode'}
                    onChange={() => {}}
                    value={`GSA-GS-2322`}
                    errorValue={null}
                    helpText={``} />

                <InputField
                    fieldLabel={`Job No`}
                    fieldName={'jobNo'}
                    onChange={() => {}}
                    value={`26999`}
                    errorValue={null}
                    helpText={``} />

                <div className="columns">
                    <div className="column is-half">

                        <InputField
                            fieldLabel={`Manufacturing Time`}
                            fieldName={'manufacturingTime'}
                            onChange={() => {}}
                            value={`4`}
                            errorValue={null}
                            helpText={``}
                            hasAddOn='hours'
                            isNumber={true} />

                    </div>
                    <div className="column is-half">

                        <InputField
                            fieldLabel={`Quantity`}
                            fieldName={'quanity'}
                            onChange={() => {}}
                            value={`2`}
                            errorValue={null}
                            helpText={``}
                            isNumber={true} />

                    </div>
                </div>

                <div className="field is-grouped is-pulled-right pt-4">
                    <div className="control">
                        <button className={`button is-link`} onClick={() => {}}>{`Edit`}</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light" onClick={onCancelModal}>Cancel</button>
                    </div>
                </div>
            </form>
        } />
    )
}

interface AddWorkFormState {

}

const initialFormState: AddWorkFormState = {

}

const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

interface FormErrorState {

}

const initialFormErrorState: FormErrorState = {

}

export enum ActiveTab {
    SCHEDULED, 
    UNSCHEDULED
}

const themeStyles = {
    NotStarted: {
        messageStyle: 'is-danger',
        borderColor: 'hsl(348, 100%, 61%)',
        icon: faExclamation,
        tooltipText: 'Not started',
    },
    Cut: {
        messageStyle: 'is-warning',
        borderColor: 'hsl(48, 100%, 67%)',
        icon: faScissors,
        tooltipText: 'Cut',
    },
    FinalChecksComplete: {
        messageStyle: 'is-primary',
        borderColor: 'hsl(171, 100%, 96%)',
        icon: faCheck,
        tooltipText: 'Final checks complete',
    },
    ReturnedFromGalv: {
        messageStyle: 'is-link',
        borderColor: 'hsl(217, 71%, 53%)',
        icon: faRightLeft,
        tooltipText: 'Returned from Galv',
    }
}

const WeeklyWorkCard = ({ 
    showWorkViewModal,
    theme,
}:{ 
    showWorkViewModal: () => void,
    theme: 'NotStarted' | 'Cut' | 'ReturnedFromGalv' | 'FinalChecksComplete',
}) => {

    return (
        <article onClick={() => showWorkViewModal()} className={`message is-clickable`}>
            <div className={`message-body p-4`} >
                <div className="columns">
                    <div className="column">
                        <h1 className="heading">Job no: 26999</h1>
                        <h1 className="title is-6 mb-2">GSA-GS-2322</h1>

                        <p className="is-size-7">2 hours total (quantity: 6)</p>
                    </div>
                    <div className="column is-narrow p-0">
                        <span data-tooltip={themeStyles[theme].tooltipText} style={{borderBottom: 0}} className={`tag ${themeStyles[theme].messageStyle} is-pulled-right is-light is-rounded`}>{ <FontAwesomeIcon size={'1x'} icon={themeStyles[theme].icon} />  }</span>
                    </div>
                </div>
            </div>
        </article>
    )
}

const DailyWorkCard = ({ 
    showWorkViewModal,
    theme,
}:{ 
    showWorkViewModal: () => void,
    theme: 'NotStarted' | 'Cut' | 'ReturnedFromGalv' | 'FinalChecksComplete',
}) => {

    return (
        <div className="column is-4">

            <article onClick={() => showWorkViewModal()} className="message is-clickable">
                <div className="message-body">
                    <div className="columns">
                        <div className="column">
                            <h1 className="heading">Job no: 26999</h1>
                            <h1 className="title is-6 mb-2">GSA-GS-2322</h1>

                            <p className="is-size-7 has-text-weight-medium">6 hours total</p>
                        </div>
                        <div className="column">
                            <span className={`tag is-pulled-right ${themeStyles[theme].messageStyle} is-light is-rounded`}>{themeStyles[theme].tooltipText}</span>
                        </div>
                    </div>

                    <div className="columns is-multiline">

                        <div className="column is-6 pb-1">

                            <div className="columns">
                                <div className="column is-2">
                                    <FontAwesomeIcon icon={faClock} size='lg' />
                                </div>
                                <div className="column">
                                    <p className="heading">Time per item</p>
                                    <p className="has-text-weight-medium is-size-7">1 hour</p>
                                </div>
                            </div>

                        </div>

                        <div className="column is-6 pb-1">

                            <div className="columns">
                                <div className="column is-2">
                                    <FontAwesomeIcon icon={faWeight} size='lg' />
                                </div>
                                <div className="column">
                                    <p className="heading">Quantity</p>
                                    <p className="has-text-weight-medium is-size-7">6</p>
                                </div>
                            </div>

                        </div>

                        <div className="column is-6">

                            <div className="columns">
                                <div className="column is-2">
                                    <FontAwesomeIcon icon={faBarcode} size='lg' />
                                </div>
                                <div className="column">
                                    <p className="heading">Item Code</p>
                                    <p className="has-text-weight-medium is-size-7">J38484-1A</p>
                                </div>
                            </div>

                        </div>

                        <div className="column is-6">

                            <div className="columns">
                                <div className="column is-2">
                                    <FontAwesomeIcon icon={faTable} size='lg' />
                                </div>
                                <div className="column">
                                    <p className="heading">Job Type</p>
                                    <p className="has-text-weight-medium is-size-7">Galv</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </article>

    </div>
    )
}

const ManageWorkshopScreen = ({
    loggedInUser,
    activeTab,
}:{
    loggedInUser: User,
    activeTab: ActiveTab
}) => {
    const { getAccessTokenSilently } = useAuth0()

    const [isAddWorkFormModalActive, setIsAddWorkFormModalActive] = useState<boolean>(false)
    const [isWorkViewModalActive, setIsWorkViewModalActive] = useState<boolean>(false)
    const [isEditWorkViewModalActive, setIsEditWorkViewModalActive] = useState<boolean>(false)
    const [isInEditState, setIsInEditState] = useState<boolean>(false)
    const [addWorkFormState, setAddWorkFormState] = useState<AddWorkFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<FormErrorState>(initialFormErrorState)
    // TODO -  implement properly via URL if we ever build this properly.
    const [activeDateView, setActiveDateView] = useState<'daily' | 'weekly' | 'monthly'>('weekly')

    const onCancelAddWorkModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setAddWorkFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
        setIsAddWorkFormModalActive(false)
        setIsInEditState(false)
    }

    const onCancelWorkViewModal = (e: React.MouseEvent) => {
        e.preventDefault()
        // setAddWorkFormState(initialFormState)
        // setFormErrorState(initialFormErrorState)
        setIsWorkViewModalActive(false)
    }

    const onCancelEditWorkViewModal = (e: React.MouseEvent) => {
        e.preventDefault()
        // setAddWorkFormState(initialFormState)
        // setFormErrorState(initialFormErrorState)
        setIsEditWorkViewModalActive(false)
    }

    return(
        <div className="container is-fullheight">

            <FormModal
                isModalActive={isAddWorkFormModalActive}
                // isModalActive={true}
                modalTitle={`${isInEditState ? `Edit `: `Add New `} Work`}
                onCancelModal={onCancelAddWorkModal}
                Form={
                    <form>

                        {/* <div className="field pb-3">
                            <label className="label">Status</label> 
                            <div className="select">
                                <select name="role" value={``} onChange={() => {}}>
                                    <option value="" disabled={true} >Select a status</option>
                                    <option value="NOT_STARTED">Not Started</option>
                                    <option value="CUT">Cut</option>
                                    <option value="RETURNED_FROM_GALV">Returned From Galv</option>
                                    <option value="FINAL_CHECKS_COMPLETE">Final Checks Complete</option>
                                </select>
                            </div>
                        </div> */}

                        <div className="columns">
                            <div className="column is-half">

                                <div className="field pb-3">
                                    <label className="label">Assign To</label> 
                                    <div className="control is-expanded has-icons-left">
                                        <div className="select is-fullwidth">
                                            <select name="assignee" value={``} onChange={() => {}}>
                                                <option value="" disabled={true} >Select a user </option>
                                                <option value="">Danny Halls</option>
                                                <option value="">Oscar Biggadike</option>
                                                <option value="">William Hallowell</option>
                        
                                            </select>
                                        </div>
                                        <div className="icon is-small is-left">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                    </div>
                                    {/* {addUserFormErrorState && addUserFormErrorState.role.isError && 
                                        <span className="help is-danger pb-1">{addUserFormErrorState.role.errorMessage}</span>
                                    } */}
                                </div>

                            </div>
                            <div className="column is-half">

                                <div className="pb-2">
                                    <label className="label">
                                        <span>Schedule Date</span>
                                    </label>
                                    <div className={`field mb-0 has-addons`}>
                                        <div className={`control`}>
                                            <input style={{width: '265px'}} className={`input is-fullwidth`} type={`date`} name={`scheduledDate`} value={``} onChange={() => {}} />
                                            {/* <p className="help">{helpText}</p> */}
                                        </div>                            
                                    </div>
                                    {/* { isShowErrorValue && <p className={`help is-danger`}>{ errorValue && errorValue.isError && errorValue.errorMessage }</p> } */}
                                </div>

                            </div>
                        </div>

                        <div className="field pb-3">
                            <label className="label">Job Type</label> 
                            <input onChange={() => {}} className="is-checkradio" id="galvJobType" type="radio" value="galv" name="jobType" checked={'galv' === 'galv'} />
                            <label htmlFor="galvJobType">Galv</label>
                            <input onChange={() => {}} className="is-checkradio" id="nonGalvJobType" type="radio" value="nonGalv" name="jobType" checked={false} />
                            <label htmlFor="nonGalvJobType">Non-Galv</label>
                        </div>
                        
                        <InputField
                            fieldLabel={`Item Code`}
                            fieldName={'itemCode'}
                            onChange={() => {}}
                            value={``}
                            errorValue={null}
                            helpText={``} />

                        <InputField
                            fieldLabel={`Grating Code`}
                            fieldName={'gratingCode'}
                            onChange={() => {}}
                            value={``}
                            errorValue={null}
                            helpText={``} />

                        <InputField
                            fieldLabel={`Job No`}
                            fieldName={'jobNo'}
                            onChange={() => {}}
                            value={``}
                            errorValue={null}
                            helpText={``} />

                        <div className="columns">
                            <div className="column is-half">

                                <InputField
                                    fieldLabel={`Manufacturing Time`}
                                    fieldName={'manufacturingTime'}
                                    onChange={() => {}}
                                    value={``}
                                    errorValue={null}
                                    helpText={``}
                                    hasAddOn='hours'
                                    isNumber={true} />

                            </div>
                            <div className="column is-half">

                                <InputField
                                    fieldLabel={`Quantity`}
                                    fieldName={'quanity'}
                                    onChange={() => {}}
                                    value={``}
                                    errorValue={null}
                                    helpText={``}
                                    isNumber={true} />

                            </div>
                        </div>

                        <div className="field is-grouped is-pulled-right pt-4">
                            <div className="control">
                                <button className={`button is-link`} onClick={() => {}}>{isInEditState ? `Edit` : `Add`}</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={onCancelAddWorkModal}>Cancel</button>
                            </div>
                        </div>

                    </form>
                } />

            <div className={`modal ${isWorkViewModalActive ? `is-active`: ``}`}>
                <div className="modal-background"></div>

                <div className="modal-card box">

                    <div className="columns mb-0 pb-0">
                        <div className="column">
                            <button className="delete is-pulled-right" onClick={onCancelWorkViewModal} aria-label="close"></button>
                        </div>
                    </div>

                    <div className="modal-card-body p-5">

                                <div className="columns is-vcentered pb-4">
                                    <div className="column">
                                        <h1 className="heading">Job no: 26999</h1>
                                        <h1 className="title is-4 mb-2">GSA-GS-2322</h1>

                                        <p className="is-size-6 has-text-weight-medium">6 hours total</p>
                                    </div>
                                    <div className="column">
                                        <span className={`tag is-large  ${themeStyles['Cut'].messageStyle} is-light is-rounded`}>{themeStyles['Cut'].tooltipText}</span>
                                    </div>

                                    <div className="column">
                                        <div className="dropdown is-right is-hoverable is-pulled-right">
                                            <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                                <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                            </div>
                                            <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                                <div className="dropdown-content">
                                                
                                                    <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => {e.preventDefault(); setIsEditWorkViewModalActive(true)}} >
                                                        <span><FontAwesomeIcon icon={faEdit} className="pr-2" /> Edit</span>
                                                    </a>
                                                    <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => {e.preventDefault()}} >
                                                        <span><FontAwesomeIcon icon={faBackward} className="pr-2" /> Return to Unscheduled</span>
                                                    </a>
                                                    <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => {}}>
                                                        <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>

                                <hr className="navbar-divider" />

                                <div className="columns is-vcentered pb-4 pt-4 mb-0">
                                    <div className="column">
                                        <h1 className="heading">Assignee</h1>
                                        <div className="columns is-vcentered">
                                            <div className="column is-narrow pr-0">
                                                <div className="avatar-circle">
                                                    <span className="initials">{`DH`}</span>
                                                </div>
                                            </div>
                                            <div className="column is-narrow">
                                                <div className="has-text-weight-medium is-size-6">{`Danny halls`}</div>
                                                <a onClick={(e: React.MouseEvent) => {e.preventDefault(); setIsEditWorkViewModalActive(true)}} href=""><span className="is-7">edit</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column">
                                        <p className="heading">SCHEDULED FOR</p>
                                        <div className="columns is-vcentered">
                                            <div className="column is-narrow pr-0">
                                                <FontAwesomeIcon icon={faCalendarXmark} size='3x' color='hsl(0, 0%, 40%)' />
                                            </div>
                                            <div className="column is-narrow">
                                                <p className="has-text-weight-medium is-size-6">20/05/2024</p>
                                                <a  onClick={(e: React.MouseEvent) => {e.preventDefault(); setIsEditWorkViewModalActive(true)}} href=""><span className="is-7">edit</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="navbar-divider" />

                                <div className="columns is-multiline pt-5 pb-5 mb-0">

                                    <div className="column is-6 pb-4">

                                        <div className="columns">
                                            <div className="column is-2">
                                                <FontAwesomeIcon icon={faClock} size='2x' color='hsl(0, 0%, 40%)' />
                                            </div>
                                            <div className="column">
                                                <p className="heading">Time per item</p>
                                                <p className="has-text-weight-medium is-size-6">1 hour</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="column is-6 pb-4">

                                        <div className="columns">
                                            <div className="column is-2">
                                                <FontAwesomeIcon icon={faWeight} size='2x' color='hsl(0, 0%, 40%)' />
                                            </div>
                                            <div className="column">
                                                <p className="heading">Quantity</p>
                                                <p className="has-text-weight-medium is-size-6">6</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="column is-6">

                                        <div className="columns">
                                            <div className="column is-2">
                                                <FontAwesomeIcon icon={faBarcode} size='2x' color='hsl(0, 0%, 40%)' />
                                            </div>
                                            <div className="column">
                                                <p className="heading">Item Code</p>
                                                <p className="has-text-weight-medium is-size-6">J38484-1A</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="column is-6">

                                        <div className="columns">
                                            <div className="column is-2">
                                                <FontAwesomeIcon icon={faTable} size='2x' color='hsl(0, 0%, 40%)' />
                                            </div>
                                            <div className="column">
                                                <p className="heading">Job Type</p>
                                                <p className="has-text-weight-medium is-size-6">Galv</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <hr className="navbar-divider" />

                                <div className="columns pt-5">
                                    <div className="column pl-0 pr-4"><button className="button is-fullwidth is-rounded is-dark is-outlined"><FontAwesomeIcon className="pr-2" icon={faArrowLeft} /> Not Started </button></div>
                                    <div className="column pl-4 pr-0"><button className="button is-fullwidth is-rounded is-dark is-outlined is-pulled-right"> Returned From Galv <FontAwesomeIcon className="pl-2" icon={faArrowRight} /> </button></div>
                                </div>
                            
                    </div>
                </div>
            </div>

            <EditWorkModal isModalActive={isEditWorkViewModalActive} onCancelModal={onCancelEditWorkViewModal} />

            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{padding: '5rem', borderRadius: 6}}>

                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3 mb-6">Manage Workshop</h1>
                    </div>
                    <div className="column">
                        <button className="button is-link is-pulled-right" onClick={() => setIsAddWorkFormModalActive(true)}>Add Work <FontAwesomeIcon className="pl-2" icon={faPlus} /></button>
                    </div>
                </div>

                <div className="tabs is-small">
                  <ul>
                    <li className={`${activeTab === ActiveTab.SCHEDULED ? `is-active` : ``}`}><Link to={`/manage/workshop/scheduled`}>Scheduled </Link></li>
                    <li className={`${activeTab === ActiveTab.UNSCHEDULED ? `is-active` : ``}`}><Link to={`/manage/workshop/unscheduled`}>Unscheduled </Link></li>
                  </ul>
                </div>


                {activeTab === ActiveTab.SCHEDULED
                ?
                <>
                <div className="columns pb-5">
                    <div className="column">

                        {activeDateView === 'daily'
                        ?
                        <>
                        <div className="columns is-vcentered">
                            <div className="column is-narrow">
                                <a href="#" onClick={(e) => { e.preventDefault(); setActiveDateView('monthly')}} className="is-5"><FontAwesomeIcon icon={faArrowLeft} size='lg' className="pr-2" /> back</a>
                            </div>
                        </div>
                        <div className="columns is-vcentered">
                            <div className="column is-narrow">
                                <FontAwesomeIcon icon={faCalendarXmark} size='3x' /> 
                            </div>
                            <div className="column">
                                <h1 className="subtitle is-4">Monday, 20th May 2024</h1>
                            </div>
                        </div>
                        {/* <div className="buttons">
                            <button className="button is-small is-light">Today</button>
                            <button className="button is-small is-light"><FontAwesomeIcon icon={faChevronLeft}/></button>
                            <button className="button is-small is-light"><FontAwesomeIcon icon={faChevronRight}/></button>
                        </div> */}
                        </>
                        : activeDateView === 'weekly' ?
                        <>
                        <div className="columns is-vcentered">
                            <div className="column is-narrow">
                                <FontAwesomeIcon icon={faCalendarXmark} size='3x' /> 
                            </div>
                            <div className="column">
                                <h1 className="heading">Week Commencing</h1>
                                <h2 className="title is-4">Monday, 20th May 2024</h2>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="button is-small is-light">Current Week</button>
                            <button className="button is-small is-light"><FontAwesomeIcon icon={faChevronLeft}/></button>
                            <button className="button is-small is-light"><FontAwesomeIcon icon={faChevronRight}/></button>
                        </div>
                        </>
                        :
                        <>
                        <div className="columns is-vcentered">
                            <div className="column is-narrow">
                                <FontAwesomeIcon icon={faCalendarDays} size='3x' /> 
                            </div>
                            <div className="column">
                                <h1 className="heading">Month</h1>
                                <h2 className="title is-4">May 2024</h2>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="button is-small is-light">Current Month</button>
                            <button className="button is-small is-light"><FontAwesomeIcon icon={faChevronLeft}/></button>
                            <button className="button is-small is-light"><FontAwesomeIcon icon={faChevronRight}/></button>
                        </div>
                        </>
                        }

                    </div>
                    {(activeDateView === 'weekly' || activeDateView === 'monthly') &&
                    <div className="column">
                        <div className="buttons has-addons is-pulled-right">
                            <button onClick={() => setActiveDateView('weekly')} className={`button ${activeDateView === 'weekly' ? `is-link is-light is-outlined is-selected` : ''}`}>Weekly</button>
                            <button onClick={() => setActiveDateView('monthly')} className={`button ${activeDateView === 'monthly' ? `is-link is-light is-outlined is-selected` : ''}`}>Monthly</button>
                        </div>
                    </div>
                    }
                </div>

                {activeDateView === 'daily'
                ?
                <>

                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Capacity</span>
                    </div>
                </div>

                <nav className="level is-mobile has-background-light p-4">
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Tasks</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">5</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Workshop Hours</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">80</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Used Hours</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">30</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                        <p className="heading">Total Remaining Hours</p>
                        <p className="title"><span className="tag is-success is-rounded is-large">50</span></p>
                        </div>
                    </div>
                </nav>

                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Tasks</span>
                    </div>
                </div>

                <div className="columns pb-4">
                    <div className="column">

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Status</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job No</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job Type</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                    </div>
                </div>

                <div className="columns is-multiline">

                    <DailyWorkCard 
                        showWorkViewModal={() => setIsWorkViewModalActive(true)}
                        theme={'FinalChecksComplete'} />

                    <DailyWorkCard 
                        showWorkViewModal={() => setIsWorkViewModalActive(true)}
                        theme={'NotStarted'} />

                    <DailyWorkCard 
                        showWorkViewModal={() => setIsWorkViewModalActive(true)}
                        theme={'Cut'} />

                    <DailyWorkCard 
                        showWorkViewModal={() => setIsWorkViewModalActive(true)}
                        theme={'ReturnedFromGalv'} />

                    <DailyWorkCard 
                        showWorkViewModal={() => setIsWorkViewModalActive(true)}
                        theme={'FinalChecksComplete'} />
                            
                </div>
                </>
                : activeDateView === 'weekly' ?
                <>

                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Capacity</span>
                    </div>
                </div>

                <nav className="level is-mobile has-background-light p-4">
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Tasks</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">16</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Workshop Hours</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">80</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Used Hours</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">32</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                        <p className="heading">Total Remaining Hours</p>
                        <p className="title"><span className="tag is-success is-rounded is-large">48</span></p>
                        </div>
                    </div>
                </nav>

                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Tasks</span>
                    </div>
                </div>

                <div className="columns pb-4">
                    <div className="column">

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Status</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job No</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job Type</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                    </div>
                </div>


                <div className="columns is-vcentered" style={{backgroundColor: '#fafafa'}}>
                    <div onClick={(e) =>  { e.preventDefault(); setActiveDateView('daily') } } className="column is-one-fifth has-text-right is-clickable" style={{borderLeft: 'rgb(240, 240, 240) 1px solid', borderRight: 'rgb(240, 240, 240) 1px solid'}}>   
                        <ColumnLabel label={`Mon`} />
                    </div>
                    <div onClick={(e) =>  { e.preventDefault(); setActiveDateView('daily') } } className="column is-one-fifth has-text-right is-clickable" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>
                        <ColumnLabel label={`Tue`} />
                    </div>
                    <div onClick={(e) =>  { e.preventDefault(); setActiveDateView('daily') } } className="column is-one-fifth has-text-right is-clickable" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>
                        <ColumnLabel label={`Wed`} /> 
                    </div>
                    <div onClick={(e) =>  { e.preventDefault(); setActiveDateView('daily') } } className="column is-one-fifth has-text-right is-clickable" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>
                        <ColumnLabel label={`Thu`} />
                    </div>
                    <div onClick={(e) =>  { e.preventDefault(); setActiveDateView('daily') } } className="column is-one-fifth has-text-right is-clickable" >
                        <ColumnLabel label={`Fri`} />
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-one-fifth" style={{borderLeft: 'rgb(240, 240, 240) 1px solid', borderRight: 'rgb(240, 240, 240) 1px solid'}}>   

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'NotStarted'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'Cut'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'NotStarted'} />

                    </div>
                    <div className="column is-one-fifth" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'FinalChecksComplete'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'FinalChecksComplete'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)} 
                            theme={'ReturnedFromGalv'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'FinalChecksComplete'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'Cut'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'FinalChecksComplete'} />

                    </div>
                    <div className="column is-one-fifth" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'FinalChecksComplete'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'Cut'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'FinalChecksComplete'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'ReturnedFromGalv'} />

                    </div>
                    <div className="column is-one-fifth" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'Cut'} />

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'NotStarted'} />

                    </div>
                    <div className="column is-one-fifth" style={{borderRight: 'rgb(240, 240, 240) 1px solid'}}>

                        <WeeklyWorkCard 
                            showWorkViewModal={() => setIsWorkViewModalActive(true)}
                            theme={'ReturnedFromGalv'} />

                    </div>
                </div>
                </>
                :
                <>
                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Capacity</span>
                    </div>
                </div>

                <nav className="level is-mobile has-background-light p-4">
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Tasks</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">20</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Workshop Hours</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">800</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Used Hours</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">260</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                        <p className="heading">Total Remaining Hours</p>
                        <p className="title"><span className="tag is-success is-rounded is-large">540</span></p>
                        </div>
                    </div>
                </nav>

                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Tasks</span>
                    </div>
                </div>

                <div className="columns pb-4">
                    <div className="column">

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Status</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job No</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job Type</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                    </div>
                </div>

                <div className="columns is-vcentered" style={{backgroundColor: '#fafafa'}}>
                    <div className="column is-1">   
                        <ColumnLabel label={`Work Date`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Grating Code`} />
                    </div>
                    <div className="column is-1">
                        <ColumnLabel label={`Quantity`} />
                    </div>
                    <div className="column is-1">   
                        <ColumnLabel label={`Total Time`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Job Type`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Job No`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Task Status`} />
                    </div>
                    <div className="column is-1">
                        {/* Leave space for actions button. */}
                    </div>
                </div>

                {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map((_, idx) => 
                    <div  className="columns is-vcentered" key={`user-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                        <div className="column is-1">
                            <a onClick={(e) =>  { e.preventDefault(); setActiveDateView('daily') } } href='#'>{`${idx + 1}/03/24`}</a>
                        </div>

                        <div className="column is-2">
                            {`GSA-GS-2322`}
                        </div>        

                        <div className="column is-1">
                            {idx}x
                        </div>

                        <div className="column is-1">
                            {idx % 2 === 0 ? `6 hours` : `4 hours`}
                        </div>

                        <div className="column is-2">
                            {idx % 2 === 0 ? `Galv` : `Non-Galv`}
                        </div>
                        <div className="column is-2">
                            {`26999`}
                        </div>
                        <div className="column is-2">
                            {
                            idx % 2 === 0 ?
                                <span className="tag is-danger is-light is-rounded">Not Started</span>
                            : idx % 3 === 0 ?
                            <span className="tag is-warning is-light is-rounded">Cut</span>
                            : idx % 7 === 0 ? 
                            <span className="tag is-link is-light is-rounded">Returned from Galv</span>
                            :
                            <span className="tag is-primary is-light is-rounded">Final Checks Complete</span>
                            }
                        </div>
                        <div className="column is-1">
                            <button onClick={() => setIsWorkViewModalActive(true)} className='button is-small is-rounded'>view</button>
                        </div>
                    </div>    
                )}

                </>
                }
                </>
                :
                <>
                <ManageWorkshopUnscheduledWorkScreen loggedInUser={loggedInUser} />
                </>
                }


                </div>
            </div>

        </div>
    )
}

export { ManageWorkshopScreen }