import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'

const SearchBar = ({
    onChange,
    onClear
}: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onClear: (e: React.MouseEvent) => void, 
}) => {

    const [searchQuery, setSearchQuery] = useState('')

    const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value)
        onChange(e)
    }

    const onClearSearchQuery = (e: React.MouseEvent) => {
        setSearchQuery('')
        onClear(e)
    }

    return(
        <div className="control has-icons-right has-icons-left">
            <input className="input" value={searchQuery} onChange={onChangeSearchQuery} type="text" />
            <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faSearch} />
            </span>
            <span className="icon is-small is-right">
                {searchQuery != '' && <FontAwesomeIcon onClick={onClearSearchQuery} className="is-clickable" icon={faXmark} /> }
            </span>
        </div>
    )
}

export { SearchBar }