import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ConfirmDeleteModal = ({
    isModalActive,
    isLoading,
    onConfirmDelete,
    onCancel,
}: {
    isModalActive: boolean,
    isLoading: boolean,
    onConfirmDelete: () => void,
    onCancel: () => void

}) => {

    return(
    <div className={`modal ${isModalActive ? `is-active`: ``}`}>
        <div className="modal-background"></div>

        <div className="modal-card box">

            <div className="modal-card-body p-5">
                <div className="columns is-vcentered p-4">
                    <div className="column is-narrow">
                        <FontAwesomeIcon icon={faExclamationTriangle} size={`3x`} />
                    </div>
                    <div className="column">
                        <h1 className="title is-4">Are you sure you want to delete?</h1>
                        <h2 className="subtitle is-6">Once you delete this item, it will no longer exist.</h2>
                    </div>
                </div>
                
                <div className="field is-grouped is-pulled-right">
                    <div className="control">
                        <button className={`button is-link ${isLoading ? `is-loading` : ``}`} onClick={onConfirmDelete}>Delete</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light" onClick={onCancel}>Cancel</button>
                    </div>
                </div>

                
            </div>
        </div>

    </div>
    )

}

export { ConfirmDeleteModal }