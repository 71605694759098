import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormInputError } from 'IndexProductScreen'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { OptionalFieldFormLabel } from './labels/OptionalFieldFormLabel'

const InputField = ({
    fieldLabel,
    onChange,
    fieldName,
    value,
    errorValue,
    helpText,
    isDisabled = false,
    isNumber = false,
    isCopyEnabled = false,
    onClickCopy = null,
    isOptional = false,
    hasAddOn,
    isExpanded = false,
    isShowErrorValue = true,
}:{
    fieldLabel: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    fieldName: string,
    value: string | number,
    errorValue: FormInputError,
    helpText: string,
    isDisabled?: boolean,
    isNumber? : boolean,
    isCopyEnabled?: boolean,
    onClickCopy?: () => void,
    isOptional?: boolean,
    hasAddOn?: string,
    isExpanded?: boolean,
    isShowErrorValue?: boolean
}) => (
    <div className="pb-2">
        <label className="label">
            <span>{fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1,fieldLabel.length)}</span>
            {isOptional && <OptionalFieldFormLabel />}
        </label>
        <div className={`field mb-0 ${hasAddOn ? `has-addons` : ``}`}>
            <div className={`control ${isCopyEnabled ? `has-icons-right` : ``} ${isExpanded ? `is-expanded` : ``}`}>
                <input className={`input ${errorValue && errorValue.isError ? `is-danger`: ''}`} type={isNumber ? `number` : `text`} name={fieldName} value={value} onChange={onChange} disabled={isDisabled} />
                {/* <p className="help">{helpText}</p> */}
                {isCopyEnabled &&
                    <span className="icon is-small is-right has-text-dark is-clickable" onClick={onClickCopy}>
                        <FontAwesomeIcon icon={faClone} />
                    </span>
                }
            </div>
            {hasAddOn &&
                <div className="control">
                    <button className={`button is-static`}>
                        {hasAddOn}
                    </button>
                </div>
            }
        </div>
        { isShowErrorValue && <p className={`help is-danger`}>{ errorValue && errorValue.isError && errorValue.errorMessage }</p> }
    </div>
)

export default InputField