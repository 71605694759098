import { faAngleDown, faClock, faEdit, faEllipsisVertical, faTractor, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from 'ManageUsersScreen'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { FormModal } from 'components/modals/FormModal'
import React, { useState } from 'react'
import { EditWorkModal } from './ManageWorkshopScreen'

const ManageWorkshopUnscheduledWorkScreen = ({
    loggedInUser,
}:{
    loggedInUser: User,
}) => {

    const [isWorkViewModalActive, setIsWorkViewModalActive] = useState<boolean>(false)
    const [isEditWorkViewModalActive, setIsEditWorkViewModalActive] = useState<boolean>(false)

    const onCancelWorkViewModal = (e: React.MouseEvent) => {
        e.preventDefault()
        // setAddWorkFormState(initialFormState)
        // setFormErrorState(initialFormErrorState)
        setIsWorkViewModalActive(false)
    }

    const onCancelEditWorkViewModal = (e: React.MouseEvent) => {
        e.preventDefault()
        // setAddWorkFormState(initialFormState)
        // setFormErrorState(initialFormErrorState)
        setIsEditWorkViewModalActive(false)
    }

    return (
        <>

                <EditWorkModal isModalActive={isEditWorkViewModalActive} onCancelModal={onCancelEditWorkViewModal} displayStatus={false} />
        

                <div className="columns">
                    <div className="column">
                        <span className="title is-5">Overview</span>
                    </div>
                </div>

                <nav className="level is-mobile has-background-light p-4">
                    <div className="level-item has-text-centered" style={{borderRight: '2px solid rgb(228, 228, 228)'}}>
                        <div>
                        <p className="heading">Total Unscheduled Tasks</p>
                        <p className="title"><span className="tag is-dark is-rounded is-large">20</span></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                        <p className="heading">Total Unscheduled Workshop Hours</p>
                        <p className="title"><span className="tag is-danger is-rounded is-large">60</span></p>
                        </div>
                    </div>
                </nav>

                <div className="columns">
                    <div className="column mb-4 mt-4">
                        <span className="title is-5">Unscheduled Tasks</span>
                    </div>
                </div>

                <div className="columns pb-4">
                    <div className="column">

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Created By</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job No</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                        <div className="dropdown pr-2">
                            <div className="dropdown-trigger">
                                <button className="button is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Job Type</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            </div>
                        </div>

                    </div>
                </div>

                <div className="columns is-vcentered" style={{backgroundColor: '#fafafa'}}>
                    <div className="column is-2">   
                        <ColumnLabel label={`Created By`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Job No`} />
                    </div>
                    <div className="column is-2">
                        <ColumnLabel label={`Grating Code`} />
                    </div>
                    <div className="column is-1">
                        <ColumnLabel label={`Quantity`} />
                    </div>
                    <div className="column is-1">
                        <ColumnLabel label={`Total Time`} />
                    </div>
                    <div className="column is-1">
                        <ColumnLabel label={`Job Type`} />
                    </div>
                    <div className="column is-1">
                        
                    </div>
                    <div className="column is-1">
                        {/* Leave space for actions button. */}
                    </div>
                </div>

                {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map((_, idx) => 
                                                        <div className="columns is-vcentered" key={`user-${idx}`} style={{borderBottom: '#e4e4e4 1px solid'}}>
                                                        <div className="column is-2">
                                                            <div className="columns is-vcentered">
                                                                <div className="column is-narrow">
                                                                    <div className="avatar-circle">
                                                                        <span className="initials">{`DH`}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-narrow">
                                                                    <div className="has-text-weight-bold">{`Danny halls`}</div>
                                                                    <div className="has-text-weight-light">{`${idx + 1}/05/24`}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column is-2">
                                                            {`J38484-1A`}
                                                        </div> 

                                                        <div className="column is-2">
                                                            {`GSA-GS-2322`}
                                                        </div>        
                
                                                        <div className="column is-1">
                                                            {idx + 1}x
                                                        </div>

                                                        <div className="column is-1">
                                                            {`3 hours`}
                                                        </div>
                
                                                        <div className="column is-1">
                                                            {idx % 2 === 0 ? `Galv` : `Non-Galv`}
                                                        </div>
                                                        <div className="column is-2">
                                                            <button className="button is-primary is-rounded is-outlined">Move to Production</button>
                                                        </div>
                                                        <div className="column is-narrow">
                                                        <div className="dropdown is-hoverable is-pulled-right">
                                                                <div className="dropdown-trigger pl-3 pr-3 is-clickable">
                                                                    <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" icon={faEllipsisVertical} size={`lg`} />
                                                                </div>
                                                                <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                                                    <div className="dropdown-content">
                                                                        <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => { e.preventDefault(); setIsEditWorkViewModalActive(true) } }>
                                                                            <span><FontAwesomeIcon icon={faEdit} className="pr-2" /> Edit </span>
                                                                        </a>
                                                                        <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => { e.preventDefault(); setIsEditWorkViewModalActive(false) } }>
                                                                            <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>    
                )}

        </>
    )



}

export { ManageWorkshopUnscheduledWorkScreen } 