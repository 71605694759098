import React from 'react'

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const QuantityInput = ({ value, onIncrement, onDecrement }: { value: number, onIncrement: () => void, onDecrement: () => void} ) => {

    return(
        <>
        <div className="field has-addons">
            <div className="control">
                <a className="button is-light is-small" onClick={onIncrement}>
                    <FontAwesomeIcon icon={faPlus} />
                </a>
            </div>
            <div className="control">
              <input className="input is-small" type="number" value={value} readOnly={true} />
            </div>
            <div className="control">
                <a className="button is-light is-small" onClick={onDecrement}>
                    <FontAwesomeIcon icon={faMinus} />
                </a>
            </div>
        </div>
      </>
    )

}

export { QuantityInput }