import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { ConfigValueItem, API_URL } from 'constants/Constants'

export interface IUseProductTypes {
    productTypes: ConfigValueItem[],
    getProductTypes: () => void,
    getProductType: (productCode: string) => ConfigValueItem | undefined
}

export const useProductTypes = () => {

    const [productTypes, setProductTypes] = useState<ConfigValueItem[]>(null)
    const { getAccessTokenSilently  } = useAuth0()

    const getProductTypes = async () => {

        const accessToken: string = await getAccessTokenSilently()

        const response = await fetch(
        `${API_URL}/configuration/product-types`
            , {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            }
        )
    
        const productTypesResp: ConfigValueItem[] = await response.json()
        const sortedProductTypes = productTypesResp.sort((a,b) => a.value.localeCompare(b.value))

        setProductTypes(sortedProductTypes)
    }

    const getProductType = (productCode: string): ConfigValueItem | undefined => {
        return productTypes && productTypes.find(_ => _.value === productCode)
    }

    return {
        productTypes,
        getProductTypes,
        getProductType
    }
}