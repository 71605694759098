import React, {useContext, useState} from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { BasketContext } from 'contexts/Basket'
import { Basket } from './Basket'
import InputField from 'components/inputs/InputField'
import { FormInputError } from 'IndexProductScreen'
import { initialFormInputErrorState } from 'hooks/useProductForm'
import { API_URL, merchants } from 'constants/Constants'
import { toast } from 'bulma-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { User } from 'ManageUsersScreen'
import { OrderStatus } from 'hooks/useOrder'
import { isMerchant } from 'models/User'


type CreateOrderFormState = {
    merchant: ICustomerDetails,
    deliveryCompanyName: string,
    deliveryPostCode: string,
    deliveryTelephoneNumber: string,
}

const initialFormState: CreateOrderFormState = {
    merchant: {
        merchantName: '',
        merchantId: '',
    },
    deliveryCompanyName: '',
    deliveryPostCode: '',
    deliveryTelephoneNumber: '',
}

interface ICustomerDetailsErrors {
    merchantId: FormInputError,
    merchantName: FormInputError,
}

interface CreateOrderFormErrorState {
    merchant: ICustomerDetailsErrors,
    deliveryCompanyName: FormInputError,
    deliveryPostCode: FormInputError,
    deliveryTelephoneNumber: FormInputError,
}

const initialFormErrorState: CreateOrderFormErrorState = {
    merchant: {
        merchantId: initialFormInputErrorState,
        merchantName: initialFormInputErrorState,
    },
    deliveryCompanyName: initialFormInputErrorState,
    deliveryPostCode: initialFormInputErrorState,
    deliveryTelephoneNumber: initialFormInputErrorState,
}

interface ICustomerDetails {
    merchantId: string,
    merchantName: string,
}

interface IDeliveryDetails {
    companyName: string,
    postCode: string,
    telephoneNumber: string,
}

interface IOrderProduct {
    productId: string,
    quantity: number
}

interface IOrderDetails {
    userId: String,
    status: OrderStatus
}

interface ICreateOrderRequest {
    products: IOrderProduct[]
    orderDetails: IOrderDetails,
    customerDetails: ICustomerDetails,
    deliveryDetails: IDeliveryDetails,
}

interface ICreateOrderResponse {
    orderId: string
}

const CreateOrderScreen = ({ loggedInUser }: { loggedInUser: User }) => {

    const Styles = {
        borderStyle: '1px solid rgb(228, 228, 228)', 
      }

    const { basketItems, clearBasket} = useContext(BasketContext)
    const { getAccessTokenSilently  } = useAuth0()
    const navigate = useNavigate()

    const [formState, setFormState] = useState<CreateOrderFormState>({
        ...initialFormState,
        ...(loggedInUser.merchant && {merchant: { merchantId: loggedInUser.merchant.id, merchantName: loggedInUser.merchant.name }})
    })
    const [formErrorState, setFormErrorState] = useState<CreateOrderFormErrorState>(initialFormErrorState)
    const [isRequestLoading, setIsRequestLoading] = useState(false)
 
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {

        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const onSelectMerchant = (e: React.ChangeEvent<HTMLSelectElement>) => {

        const selectedMerchant = merchants.find(_ => _.id === e.target.value)

        setFormState({
            ...formState,
            [e.target.name]: {
                merchantId: selectedMerchant.id,
                merchantName: selectedMerchant.name
            }
        })
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustBeGreaterThanZero: string = `Must be greater than zero`

        const isError = (value: string | number) => value === null || value === '' || (typeof value === 'number' && value == 0)  
        
        const formErrors: CreateOrderFormErrorState = Object.entries(formState).reduce((acc, [key, value]) => {

            if (key === 'merchant') {
                const merchant: ICustomerDetails = value as ICustomerDetails
               
                return {
                    ...acc,
                    merchant: {
                        merchantId: {
                            isError: isError(merchant.merchantId),
                            errorMessage: merchant.merchantId === null || merchant.merchantId === '' ? mustNotBeEmptyError : (typeof merchant.merchantId === 'number' && merchant.merchantId == 0)  ? mustBeGreaterThanZero : null,
                        },
                        merchantName: {
                            isError: isError(merchant.merchantName),
                            errorMessage: merchant.merchantName === null || merchant.merchantName === '' ? mustNotBeEmptyError : (typeof merchant.merchantName === 'number' && merchant.merchantName == 0)  ? mustBeGreaterThanZero : null,
                        },
                    }
                }
            }
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '' || (typeof value === 'number' && value == 0),
                    errorMessage: value === null || value === '' ? mustNotBeEmptyError : (typeof value === 'number' && value == 0)  ? mustBeGreaterThanZero : null,
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            if (key === 'merchant') {
                const merchantErrors: ICustomerDetailsErrors = value
                return merchantErrors.merchantId.isError === false && merchantErrors.merchantName.isError === false
            }

            const formError: FormInputError = value
            return formError.isError === false
        })
    }

    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const accessToken: string = await getAccessTokenSilently()
            
            setIsRequestLoading(true)

            const createOrderRequest: ICreateOrderRequest = {
                products: basketItems.map(_ => ({
                    productId: _.product.objectID,
                    quantity: _.quantity
                })),
                orderDetails: {
                    userId: loggedInUser.user_id,
                    status: 'live'
                },
                customerDetails: {
                    merchantName: formState.merchant.merchantName,
                    merchantId: formState.merchant.merchantId,
                },
                deliveryDetails: {
                    companyName: formState.deliveryCompanyName,
                    postCode: formState.deliveryPostCode,
                    telephoneNumber: formState.deliveryTelephoneNumber,
                }
            }

            const response = await fetch(
                `${API_URL}/orders`
                    , {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(createOrderRequest)
                }); 

            if (response.status === 201) {
                toast({
                    message: `Success, order created.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  const responseBody = await response.json()

                  console.log(responseBody)

                  setIsRequestLoading(false)
                  setFormState(initialFormState)
                  setFormErrorState(initialFormErrorState)
                  clearBasket()
                  navigate(`/order/summary/${responseBody.orderId}`)

            } else {
                setIsRequestLoading(false)

                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }
        }
    }
    
    return(
        <div className="container is-fullheight">

            <div className="columns mt-6 pt-6">

                <div className="column is-7 has-background-white" style={{padding: '5rem 4rem 5rem 4rem'}}>

                    <h1 className="title is-3">Order Details</h1>

                    {!isMerchant(loggedInUser) &&
                        <div className="block pt-4 pb-6 pl-6 pr-6" style={{borderBottom: Styles.borderStyle}}>
                            <h2 className="subtitle is-4">Customer Details</h2>
                            <div className="block">
                                                
                                <article className="message">
                                    <div className="message-body">
                                        <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />
                                        As an employee you are creating an order, acting on behalf of the merchant you select below.
                                    </div>
                                </article>

                                <div className="field pb-1">
                                    <label className="label">Merchant</label> 
                                    <div className="select">
                                        <select name="merchant" value={formState.merchant.merchantId} onChange={onSelectMerchant}>
                                            <option value="" disabled={true} >Select a merchant</option>
                                            {merchants.map((merchant, i) => (
                                                <option value={merchant.id} key={`merchant-${i}`}>{merchant.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    { (formErrorState.merchant.merchantId.isError || formErrorState.merchant.merchantName.isError) && <p className={`help is-danger`}>{ formErrorState.merchant.merchantName && formErrorState.merchant.merchantName.isError && formErrorState.merchant.merchantName.errorMessage }</p> }
                                </div>

                            </div>
                        </div>
                    }

                    <div className="block pt-4 pl-6 pr-6">
                        <h2 className="subtitle is-4">Delivery Details</h2>
                        <div className="block">
                            <InputField
                                fieldLabel={`Company Name`}
                                fieldName={'deliveryCompanyName'}
                                onChange={onChangeInput}
                                value={formState.deliveryCompanyName}
                                errorValue={formErrorState.deliveryCompanyName}
                                helpText={`The company name`} />

                            <InputField
                                fieldLabel={`Post Code`}
                                fieldName={'deliveryPostCode'}
                                onChange={onChangeInput}
                                value={formState.deliveryPostCode}
                                errorValue={formErrorState.deliveryPostCode}
                                helpText={`The delivery post code`} />

                            <InputField
                                fieldLabel={`Telephone Number`}
                                fieldName={'deliveryTelephoneNumber'}
                                onChange={onChangeInput}
                                value={formState.deliveryTelephoneNumber}
                                errorValue={formErrorState.deliveryTelephoneNumber}
                                helpText={`Telephone number`} />
                        </div>
                    </div>

                    <div className="block pl-6 pr-6">
                        <a onClick={(e) => {e.preventDefault(); navigate('/')}}><FontAwesomeIcon icon={faLeftLong} /> Continue searching</a>
                        <a className="is-pulled-right" onClick={(e) => { e.preventDefault(); setFormState(initialFormState)} }>Clear</a>
                    </div>
                    
                </div>
                
                <div className="column is-5 has-background-white" style={{borderLeft: Styles.borderStyle, padding: '5rem 4rem 5rem 4rem'}}>
                    <h1 className="title is-3">Review your order</h1>
                    <Basket>
                        <button onClick={onSubmitForm} className={`button is-link is-fullwidth ${isRequestLoading ? 'is-loading' : null}`}>Complete Order</button>
                    </Basket>
                </div>

            </div>
            
        </div>
    )
}

export { CreateOrderScreen }