import { useAuth0 } from '@auth0/auth0-react'
import { faCalendar, faCalendarAlt, faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pdf } from "@react-pdf/renderer"
// @ts-ignore
import saveAs from 'save-as'
import { User } from 'ManageUsersScreen'
import { toast } from 'bulma-toast'
import { ProductionAndQualityChecklist, productToChecklistData } from 'components/PDF/ProductionAndQualityChecklist'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { API_URL, DATE_TIME_FORMAT } from 'constants/Constants'
import { OrderStatus, useOrder } from 'hooks/useOrder'
import { useProductTypes } from 'hooks/useProductTypes'
import JSZip, { JSZipObject } from 'jszip'
import moment from 'moment'
import { ReadOnlyBasket } from 'pages/orders/Basket'
import { OrderDetailsPanel } from 'pages/orders/OrderSummaryScreen'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isMerchant } from 'models/User'
import { Permissions } from 'AppController'


type FileForZip = {
    fileName: string,
    blob: Blob
}

const OrderDetailsScreen = ({ loggedInUser }:{ loggedInUser: User }) => {

    const { getAccessTokenSilently  } = useAuth0()
    const { getProductTypes, getProductType } = useProductTypes()
    const [isChangeOrderStatusLoading, setIsChangeOrderStatusLoading] = useState<boolean>(false)
    const { orderId } = useParams<{orderId: string}>()
    const { order, setDidOrderUpdate } = useOrder(orderId)

    useEffect(() => {      
        getProductTypes()
      //   .catch((error) => setSearchError(error))    
  }, [])

    const Styles = {
        borderStyle: '1px solid rgb(228, 228, 228)', 
      }

    const onChangeOrderStatus = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    
        const newStatus: string = e.target.value

        const changeOrderStatusRequest = {
            status: newStatus
        }

        const accessToken: string = await getAccessTokenSilently()

        setIsChangeOrderStatusLoading(true)

        const response = await fetch(
        `${API_URL}/orders/${orderId}/status`
            , {
            method: 'PATCH',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(changeOrderStatusRequest)
            }
        )

        if (response.status === 200) {
            toast({
                message: `Success, order status updated.`,
                type: 'is-link',
                position: 'bottom-right',
                })

                setIsChangeOrderStatusLoading(false)
                setDidOrderUpdate(true)
                
        } else {
            setIsChangeOrderStatusLoading(false)

            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
                })
        }
    }

    const onDownloadProductionChecklists = async () => {
        const zip = new JSZip()

        const checklists: Promise<FileForZip>[] = order.products.map(async (product) => {
            const fileName: string = await Promise.resolve(`${product.product.productCode}_Production_And_Quality_Control_CheckList.pdf`)
            const fileBlob: Blob = await pdf(<ProductionAndQualityChecklist checklistData={productToChecklistData(product.product, getProductType(product.product.productType))} />).toBlob()
            
            return {fileName: fileName, blob: fileBlob}
        })

        const allFiles: FileForZip[] = await Promise.all(checklists)

        allFiles.forEach((f) => {
            zip.file(f.fileName, f.blob)
        })

        const finalZipFile: Blob = await zip.generateAsync({ type: "blob" })

        return await saveAs(finalZipFile, `order_${orderId}_producion_checklists.zip`)
    }

    return(
        <div className="container is-fullheight"> {/* TODO - EXTRACT OUT AS PAGE LAYOUT */}
            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{padding: '5rem 4rem 5rem 4rem'}}>

                {/* TODO - EXTRACT OUT AS PAGE TITLE COMPONENT */}
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3 mb-6">Order Details</h1>
                    </div>
                </div>

                {order 
                    ?
                    <>
                    <div className="columns is-vcentered pb-2" style={{borderBottom: Styles.borderStyle}}>
                        <div className="column">
                            <h2 className="subtitle is-5 mb-2">
                                <FontAwesomeIcon icon={faCalendarAlt} className='pr-2'/>
                                {moment(order.orderDetails.timestamp, DATE_TIME_FORMAT).format('ddd, Do MMMM YYYY, h:mma')}
                            </h2>
                            <span className="is-5 has-text-grey">Order ID: {order.orderId}</span>
                        </div>
                        {!isMerchant(loggedInUser) &&
                            <div className="column">

                                <div className="field is-grouped is-pulled-right">
                                    <div className="control">
                                        <div className={`select 
                                        ${
                                            isChangeOrderStatusLoading ? `is-loading` : ``
                                        }
                                        ${
                                            order.orderDetails.status === 'live' ? `is-info`
                                            : order.orderDetails.status === 'on_hold' ? 'is-warning'
                                            : order.orderDetails.status === 'cancelled' ? 'is-danger'
                                            : order.orderDetails.status === 'complete' ? 'is-primary'
                                            : ''
                                        }
                                        }`}>
                                            <select name="status" value={order.orderDetails.status} onChange={onChangeOrderStatus}>
                                                <option value="" disabled={true} >Change Order Status</option>
                                                <option value="live">Live</option>
                                                <option value="on_hold">On Hold</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="complete">Complete</option>
                                            </select>
                                        </div>
                                        {/* <span className="help is-danger pb-1">{``}</span> */}
                                    </div>
                                    {Permissions.canDownloadProductionChecklist(loggedInUser) &&
                                        <div className="control">
                                            <button className="button is-link" onClick={onDownloadProductionChecklists}>
                                                <FontAwesomeIcon icon={faDownload} className='pr-2' />
                                                Production Checklists
                                            </button>
                                        </div>
                                    }
                                </div>

                            </div>
                        }
                    </div>

                    <div className="p-6 mt-6 has-background-warning-light" style={{borderRadius: '30px'}}>
                        <OrderDetailsPanel order={order} />
                    </div>
                    
                    <div className="mt-6">
                        <ReadOnlyBasket basketItems={order.products} />
                    </div>
                
                    </>
                    :

                    <PageLoadSpinner />
                    }

                </div>
            </div>
        </div>
        
    )
}

export { OrderDetailsScreen }