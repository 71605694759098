import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { ChecklistData, ProductionAndQualityChecklist } from 'components/PDF/ProductionAndQualityChecklist'

const TestPDFScreen = () => {

    const checklistData: ChecklistData = {
        pieceWeight: 1000,
        headwallName: 'H6CC',
        drawingNo: 'H6CC-560379',
        jobIssueDate: '01/09/2023',
         pipeOpenings: [
            {
                "insideDiameter": 225,
                "material": "CHERRY_PIPES_TWINWALL",
                "outsideDiameter": 266,
                "openingSize": 300,
                "pipeId": "CHERRY_PIPES_TWINWALL_225",
                "invertLevel": 134.5,
                "openingLevel": 97
            },
            {
                "insideDiameter": 300,
                "material": "CHERRY_PIPES_TWINWALL",
                "outsideDiameter": 354,
                "openingSize": 380,
                "pipeId": "CHERRY_PIPES_TWINWALL_300",
                "invertLevel": 50,
                "openingLevel": 10
            }
        ],
        toeBeamCode: 'T-301 A',
        steelworkCodes: ['foo'],
        handrailCode: 'K40',
        flowControlItems: [
            {
                size: 200,
                type: 'DUCKBILL_VALVE'
            },
            {
                size: 300,
                type: 'FLAP_VALVE'
            }
        ],
        reinforcementWireCode: 'RW-H6CC'     
    }

    return(
        <div style={{height: '2000px'}}>
            <PDFViewer width={"100%"} height="100%" showToolbar={true}>
                <ProductionAndQualityChecklist checklistData={checklistData} />
            </PDFViewer>
        </div>
    )

}

export { TestPDFScreen }