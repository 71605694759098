import React, { createContext, useState, useEffect, ReactNode } from 'react'

import { ProductSearchResult } from 'SearchScreen'


export interface BasketItem {
    product: ProductSearchResult,
    quantity: number,
}

export type BasketContextType = {
    basketItems: BasketItem[]
    getBasketItem: (productid: string) => BasketItem,
    addToBasket: (basketItem: BasketItem) => void,
    removeFromBasket: (productid: string) => void, // reduces quantity of item
    clearProductFromBasket: (productid: string) => void, // completely removes product
    clearBasket: () => void,
}

export const BasketContext = createContext<BasketContextType>(null)

export const BasketProvider = ({children}: {children: ReactNode}) => {

  const LOCAL_STORAGE_KEY = 'basketItems'

  const [basketItems, setBasketItems] = useState<BasketItem[]>(localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) : [])

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(basketItems));

  }, [basketItems])

  useEffect(() => {
    const basketItems = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (basketItems) {
      setBasketItems(JSON.parse(basketItems))
    }
  }, []);

  const getBasketItem = (productId: string): BasketItem => {
    return basketItems.find(_ => productId === _.product.objectID)
  }
  
  
  const addToBasket = (basketItem: BasketItem) => {
    const isItemInBasket = basketItems.find((item) => basketItem.product.objectID === item.product.objectID)

    if (isItemInBasket) {

        setBasketItems(basketItems.map((item) => {
            if (item.product.objectID === basketItem.product.objectID) {
                return { product: item.product, quantity: item.quantity + basketItem.quantity }
            }
            else return item
        }))

    } else setBasketItems([...basketItems, basketItem ])

  }

  const removeFromBasket = (productid: string) => {

    const isItemInBasket = basketItems.find((item) => item.product.objectID ===  productid)

    if (isItemInBasket && isItemInBasket.quantity === 1) {

      setBasketItems(basketItems.filter((item) => item.product.objectID !== productid))

    } else {

      setBasketItems(
        basketItems.map((item) => {
          if (item.product.objectID === productid) {
            return { ...item, quantity: item.quantity - 1 }
          }
        
          return item
        }
      ))

    }
  };

  const clearProductFromBasket = (productid: string) => {
      setBasketItems(basketItems.filter((item) => item.product.objectID !== productid))
  };

  const clearBasket = () => {
    setBasketItems([]);
  };

  return (
    <BasketContext.Provider
      value={{
        basketItems,
        getBasketItem,
        addToBasket,
        removeFromBasket,
        clearProductFromBasket,
        clearBasket
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};