import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { ConfigValueItem, API_URL } from 'constants/Constants'

export interface IUsePipeMaterials {
    pipeMaterials: ConfigValueItem[],
    getPipeMaterials: () => void
}

export const usePipeMaterials = () => {

    const [pipeMaterials, setPipeMaterials] = useState<ConfigValueItem[]>(null)
    const { getAccessTokenSilently  } = useAuth0()

    const getPipeMaterials = async () => {

        const accessToken: string = await getAccessTokenSilently()

        const response = await fetch(
        `${API_URL}/configuration/pipe-materials`
            , {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            }
        )
    
        const pipeMaterialsResp: ConfigValueItem[] = await response.json()
        const sortedPipeMaterials = pipeMaterialsResp.sort((a,b) => a.value.localeCompare(b.value))
        setPipeMaterials(sortedPipeMaterials)
    }

    return {
        pipeMaterials,
        getPipeMaterials
    }
}