import React from 'react'

import { IProductSearchResponse } from 'SearchScreen'
import { SearchFilter } from './SearchFilter'
import { SearchFilterSelection, SearchFilters } from 'hooks/useProductFilters'

const ProductFiltersMenu = ({
    productSearchResponse,
    productFilters,
    onCheckProductFilter,
    onCheckNoneFilter,
    menuSize = 'normal'
}: {
    productSearchResponse: IProductSearchResponse,
    productFilters: SearchFilters,
    onCheckProductFilter: (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => void,
    onCheckNoneFilter?: (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => void,
    menuSize?: 'normal' | 'small'
}) => {

    return (

        <div className="columns mb-6 is-multiline">

            {productSearchResponse && productSearchResponse.facets && productSearchResponse.facets.productType &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Product Type`}
                filterName={`productType`}
                facet={productSearchResponse.facets.productType}
                searchFilterSelection={productFilters.productType}
                onCheckFilter={onCheckProductFilter} 
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.toe || productSearchResponse?.facets?.hasToe)  &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Toe`}
                filterName={`toe`}
                facet={productSearchResponse.facets.toe}
                hasNoneFacet={productSearchResponse.facets.hasToe}
                searchFilterSelection={productFilters.toe}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.['pipeOpenings.insideDiameter'] || productSearchResponse?.facets?.hasPipeOpenings) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Pipe Inside Diameter`}
                filterName={`insideDiameter`}
                facet={productSearchResponse.facets['pipeOpenings.insideDiameter']}
                hasNoneFacet={productSearchResponse.facets.hasPipeOpenings}
                searchFilterSelection={productFilters.insideDiameter}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                facetValueMetric={`mm`}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.['pipeOpenings.invertLevel'] || productSearchResponse?.facets?.hasPipeOpenings) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Pipe Invert Level`}
                filterName={`invertLevel`}
                facet={productSearchResponse.facets['pipeOpenings.invertLevel']}
                hasNoneFacet={productSearchResponse.facets.hasPipeOpenings}
                searchFilterSelection={productFilters.invertLevel}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                facetValueMetric={`mm`}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.['pipeOpenings.openingLevel'] || productSearchResponse?.facets?.hasPipeOpenings) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Pipe Opening Level`}
                filterName={`openingLevel`}
                facet={productSearchResponse.facets['pipeOpenings.openingLevel']}
                hasNoneFacet={productSearchResponse.facets.hasPipeOpenings}
                searchFilterSelection={productFilters.openingLevel}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                facetValueMetric={`mm`}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.['pipeOpenings.material'] || productSearchResponse?.facets?.hasPipeOpenings) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Pipe Material`}
                filterName={`pipeMaterial`}
                facet={productSearchResponse.facets['pipeOpenings.material']}
                hasNoneFacet={productSearchResponse.facets.hasPipeOpenings}
                searchFilterSelection={productFilters.pipeMaterial}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                displayPretty={true}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.handrail || productSearchResponse?.facets?.hasHandrail) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Handrail`}
                filterName={`handrail`}
                facet={productSearchResponse.facets.handrail}
                hasNoneFacet={productSearchResponse.facets.hasHandrail}
                searchFilterSelection={productFilters.handrail}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.steelworkCode || productSearchResponse?.facets?.hasSteelwork) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Steelwork Code`}
                filterName={`steelworkCode`}
                facet={productSearchResponse.facets.steelworkCode}
                hasNoneFacet={productSearchResponse.facets.hasSteelwork}
                searchFilterSelection={productFilters.steelworkCode}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.['flowControlDevices.size'] || productSearchResponse?.facets?.hasFlowControlDevice)  &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Flow Control Size`}
                filterName={`flowControlDeviceSize`}
                facet={productSearchResponse.facets['flowControlDevices.size']}
                hasNoneFacet={productSearchResponse.facets.hasFlowControlDevice}
                searchFilterSelection={productFilters.flowControlDeviceSize}
                onCheckFilter={onCheckProductFilter} 
                onCheckNoneFilter={onCheckNoneFilter}
                facetValueMetric={`mm`}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.['flowControlDevices.type'] || productSearchResponse?.facets?.hasFlowControlDevice) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Flow Control Type`}
                filterName={`flowControlDeviceType`}
                facet={productSearchResponse.facets['flowControlDevices.type']}
                hasNoneFacet={productSearchResponse.facets.hasFlowControlDevice}
                searchFilterSelection={productFilters.flowControlDeviceType}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                displayPretty={true}
                isSmall={menuSize === 'small'} />
            </div>
            }

            {(productSearchResponse?.facets?.secondaryCasting || productSearchResponse?.facets?.hasSecondaryCasting) &&
            <div className="column is-narrow">
                <SearchFilter
                filterLabel={`Secondary Casting`}
                filterName={`secondaryCasting`}
                facet={productSearchResponse.facets.secondaryCasting}
                hasNoneFacet={productSearchResponse.facets.hasSecondaryCasting}
                searchFilterSelection={productFilters.secondaryCasting}
                onCheckFilter={onCheckProductFilter}
                onCheckNoneFilter={onCheckNoneFilter}
                isSmall={menuSize === 'small'} />
            </div>
            }

        </div>

    )
}

export { ProductFiltersMenu }