import { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { API_URL } from 'constants/Constants'
import { IProductSearchResponse } from 'SearchScreen'
import { EDITS_REQUESTED, PENDING_REVIEW } from 'pages/manage/products/ManageProductsScreen'

export type ManageProductCounts = {
    pendingReview: number,
    editsRequested: number,
}

export const useManagedProductCounts = () => {

    const [manageProductCounts, setManageProductCounts] = useState<ManageProductCounts>(null)
    const [state, refetch] = useState({})
    const refresh = () => refetch({});

    const { getAccessTokenSilently  } = useAuth0()

    useEffect(() => {

        const getManageProductCounts = async () => {

            const accessToken: string = await getAccessTokenSilently()
        
            const getCount = async (filter: string) => {
                const response = await fetch(
                `${API_URL}/products/search?metadata.status=${filter}`
                    , {
                    method: 'GET',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    }
                })
    
                const json: IProductSearchResponse = await response.json()
                return json.noOfResults
            } 
    
            const response = await Promise.all([
                getCount(PENDING_REVIEW),
                getCount(EDITS_REQUESTED),
            ])
    
            setManageProductCounts({
                pendingReview: response[0],
                editsRequested: response[1],
            })
        }

        getManageProductCounts()
           
    }, [state])

    return {
        manageProductCounts,
        refresh
    }
}