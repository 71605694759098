import React, { ReactElement, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faFileCirclePlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { drawingCategories } from 'constants/Constants'
import { AddAnotherBtn } from 'components/buttons/AddAnotherBtn'
import { IUseDesignDocumentsForm } from 'hooks/useDesignDocumentsForm'


const DesignDocumentsForm = ( { formView, designDocumentsForm, isMerchant } : { formView: 'add' | 'edit' | 'view', designDocumentsForm: IUseDesignDocumentsForm, isMerchant: boolean } ) => {

    const { 
        formState,
        onAddDesignDocument,
        onDeleteDesignDocument,
        onChangeDesignDocumentCategory,
        onChangeFileInput,
        removeAsset
     } = designDocumentsForm 

    return (

            <>
            {(formState && formState.filter(_ => isMerchant ? _.category.category === 'ALTHON' : true).length) > 0
            ?
            <>
            {formState && formState.filter(_ => isMerchant ? _.category.category === 'ALTHON' : true).map((doc, idx) => (
                <div className="columns is-vcentered" key={idx}>
                    <div className="column"> 
                        <div className="field">
                            <label className="label">Category</label> 
                            <div className={`select ${doc.category.error ? `is-danger`: ''}`}>
                                <select disabled={formView === 'view'} name="category" value={doc.category.category} onChange={(e) => onChangeDesignDocumentCategory(e, idx)}>
                                    <option value="" disabled={true} >Select a category</option>
                                    {drawingCategories.map((dc, idx) => 
                                        <option key={`drawing-code-${idx}`} value={dc.value}>{dc.label}</option>
                                    )}
                                </select>
                            </div>
                            {doc && doc.category.error && 
                                <span className="help is-danger">{doc.category.error}</span>
                            }
                        </div>
                    </div>

                    <div className="column pb-0">
                        <div className="field">
                        <label className="label">{formView !== 'view' ? `Select a drawing` : `Drawing`}</label>
                            {!(doc && doc.asset.fileName && doc.asset.fileExtension && formView === 'view') &&
                                <div className={`file pb-3`}>
                                    <label className="file-label" style={{...(doc && doc.asset.error && {border: 'hsl(348deg, 86%, 61%) 1px solid', borderRadius: '4px'} )}}>
                                        <input onChange={(e) => onChangeFileInput(e, idx)} className={`file-input`} type="file" name={`drawing-${idx}`} />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                                <FontAwesomeIcon icon={faFileCirclePlus} />
                                            </span>
                                            <span className="file-label">
                                                Choose a file…
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            }
                            {doc && doc.asset.fileName && doc.asset.fileExtension &&
                                <>
                                <a className="is-underlined is-size-6" target="_blank" href={doc.asset.sourceUrl}>{doc.asset.fileName + '.' +  doc.asset.fileExtension} </a>
                                {doc.asset.isLoading 
                                    ? <FontAwesomeIcon className="ml-2 is-clickable" icon={faCircleNotch} spin={true} />
                                    : doc.asset.isUploaded 
                                    ? formView !== 'view' && <FontAwesomeIcon className="ml-2 is-clickable" icon={faXmark} onClick={() => removeAsset(idx)} />
                                    : null
                                }
                                </>
                            }
                            {doc && doc.asset.error && 
                                <span className="help is-danger">{doc.asset.error}</span>
                            }
                        </div>
                    </div>
                    
                    {formView !== 'view' &&
                        <div className="column is-narrow">
                            {formState.length > 1 &&
                                <span className="is-clickable" onClick={(e: React.MouseEvent) => onDeleteDesignDocument(e, idx)}>{<FontAwesomeIcon icon={faXmark} />}</span>
                            }
                        </div>
                    }
                </div>
            ))
            }
            </>
            :
            <span className="is-italic is-size-6">None</span>
            }

            {formView !== 'view' &&
                <div className="field">
                    <AddAnotherBtn onClick={onAddDesignDocument} />
                </div>
            }
            </> 

    )
}

export { DesignDocumentsForm }