import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const AddAnotherBtn = ({ onClick }:{ onClick: (e: React.MouseEvent) => void }) => (    
    <button className="button is-small is-info is-light is-outlined" onClick={onClick}>
        <span>Add another {<FontAwesomeIcon icon={faAdd} className="pl-2" />}</span>
    </button>
)

export { AddAnotherBtn }