
export const API_URL = process.env.API_URL;

export const DATE_TIME_FORMAT = 'MMMM Do YYYY, h:mm:ss'
export const DATE_FORMAT = 'MMMM Do YYYY'

export const PAID_ADD_ONS = {
    quarantineZone: {
        isEnabled: true
    },
    manufacturing: {
        isEnabled: process.env.STAGE === 'STAGING' || process.env.STAGE === 'DEV'
    }
}

export type Measurement = {
    value: number,
    unitOfMeasurement: 'mm'
}

export type Weight = {    
    value: number,
    unitOfMeasurement: 'kg'
}

export type ConfigValueItem = {
    label: string,
    value: string,
    shortDescription?: string,
    weight?: Weight,
    externalBackHeight?: Measurement,
    internalBackWidth?: Measurement,
    isDeleted?: string
}

export const merchants = [
    {
        id: 'f9157d2d-a8af-4d1c-a1d8-fa33de3e56af',
        name: 'Althon'
    }
]

export const toeBeamCodes = [
    {
        code: 'T-301 A',
        eligibleProductTypes: ['AH4'],
        width: {
            value: 1300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 450,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 420,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-301 B',
        eligibleProductTypes: ['AH4'],
        width: {
            value: 1300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 700,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 655,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-301 C',
        eligibleProductTypes: ['AH4'],
        width: {
            value: 1300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 940,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-501 A',
        eligibleProductTypes: ['AH4'],
        width: {
            value: 1300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 780,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-501 B',
        eligibleProductTypes: ['AH4'],
        width: {
            value: 1300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1170,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-302 A',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 450,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-302 B',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 725,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-302 C',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1060,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-302 D',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1147,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-502 A',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 930,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-502 B',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1395,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-502 C',
        eligibleProductTypes: ['RSFA11', 'RSFA12', 'R10', 'SFA6'],
        width: {
            value: 1550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1860,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-303 A',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 665,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-303 B',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1080,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-303 C',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1575,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-503 A',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1100,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-503 B',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1380,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-503 C',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2070,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-503 D',
        eligibleProductTypes: ['RSFA18', 'RSFA20'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2760,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-304 A',
        eligibleProductTypes: ['SFA10', 'SFA11', 'SP10', 'SP11', 'CSP10'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 450,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 745,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-304 B',
        eligibleProductTypes: ['SFA10','SFA11', 'SP10', 'SP11', 'CSP10'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 700,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1160,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-304 C',
        eligibleProductTypes: ['SFA10','SFA11', 'SP10', 'SP11', 'CSP10'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1660,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-504 A',
        eligibleProductTypes: ['SFA10', 'SFA11', 'SP10', 'SP11', 'CSP10'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1380,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-504 B',
        eligibleProductTypes: ['SFA10', 'SFA11', 'SP10', 'SP11', 'CSP10'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2070,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-504 C',
        eligibleProductTypes: ['SFA10', 'SFA11', 'SP10', 'SP11', 'CSP10'],
        width: {
            value: 2300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2760,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-305 A',
        eligibleProductTypes: ['RSFA18_ANGLED', 'RSFA20_ANGLED',],
        width: {
            value: 2800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 810,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-305 B',
        eligibleProductTypes: ['RSFA18_ANGLED', 'RSFA20_ANGLED',],
        width: {
            value: 2800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1310,
            unitOfMeasurement: 'kg'  
        } 
    },
    {
        code: 'T-305 C',
        eligibleProductTypes: ['RSFA18_ANGLED', 'RSFA20_ANGLED',],
        width: {
            value: 2800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1915,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-505 A',
        eligibleProductTypes: ['RSFA18_ANGLED', 'RSFA20_ANGLED',],
        width: {
            value: 2800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1680,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-505 B',
        eligibleProductTypes: ['RSFA18_ANGLED', 'RSFA20_ANGLED',],
        width: {
            value: 2800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2520,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-505 C',
        eligibleProductTypes: ['RSFA18_ANGLED', 'RSFA20_ANGLED',],
        width: {
            value: 2800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3360,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-306 A',
        eligibleProductTypes: ['AH12'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 950,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-306 B',
        eligibleProductTypes: ['AH12'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1545,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-306 C',
        eligibleProductTypes: ['AH12'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2260,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-506 A',
        eligibleProductTypes: ['AH12'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1980,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-506 B',
        eligibleProductTypes: ['AH12'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2970,
            unitOfMeasurement: 'kg'  
        } 
    },
    {
        code: 'T-506 C',
        eligibleProductTypes: ['AH12'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3960,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-307 A',
        eligibleProductTypes: ['SFA15', 'SFA16s', 'SFA16', 'SP15', 'SP16', 'CSP15'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 950,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-307 B',
        eligibleProductTypes: ['SFA15', 'SFA16s', 'SFA16', 'SP15', 'SP16', 'CSP15'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1545,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-307 C',
        eligibleProductTypes: ['SFA15', 'SFA16s', 'SFA16', 'SP15', 'SP16', 'CSP15'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2260,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-507 A',
        eligibleProductTypes: ['SFA15', 'SFA16s', 'SFA16', 'SP15', 'SP16', 'CSP15'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1705,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-507 B',
        eligibleProductTypes: ['SFA15', 'SFA16s', 'SFA16', 'SP15', 'SP16', 'CSP15'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2700,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-507 C',
        eligibleProductTypes: ['SFA15', 'SFA16s', 'SFA16', 'SP15', 'SP16', 'CSP15'],
        width: {
            value: 3300,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3685,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-308 A',
        eligibleProductTypes: ['SFA20', 'SFA21', 'SP20', 'CSP20'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1095,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-308 B',
        eligibleProductTypes: ['SFA20', 'SFA21', 'SP20', 'CSP20'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1780,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-308 C',
        eligibleProductTypes: ['SFA20', 'SFA21', 'SP20', 'CSP20'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2600,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-508 A',
        eligibleProductTypes: ['SFA20', 'SFA21', 'SP20', 'CSP20'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1960,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-508 B',
        eligibleProductTypes: ['SFA20', 'SFA21', 'SP20', 'CSP20'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3100,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-508 C',
        eligibleProductTypes: ['SFA20', 'SFA21', 'SP20', 'CSP20'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 4240,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-309 A',
        eligibleProductTypes: ['SFA27', 'SFA28', 'SP27', 'CSP27'],
        width: {
            value: 4550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 400,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1310,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-309 B',
        eligibleProductTypes: ['SFA27', 'SFA28', 'SP27', 'CSP27'],
        width: {
            value: 4550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 650,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2130,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-309 C',
        eligibleProductTypes: ['SFA27', 'SFA28', 'SP27', 'CSP27'],
        width: {
            value: 4550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 950,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3115,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-509 A',
        eligibleProductTypes: ['SFA27', 'SFA28', 'SP27', 'CSP27'],
        width: {
            value: 4550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2350,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-509 B',
        eligibleProductTypes: ['SFA27', 'SFA28', 'SP27', 'CSP27'],
        width: {
            value: 4550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3715,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-509 C',
        eligibleProductTypes: ['SFA27', 'SFA28', 'SP27', 'CSP27'],
        width: {
            value: 4550,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 5080,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-310 A',
        eligibleProductTypes: ['N10'],
        width: {
            value: 1850,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 670,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-310 B',
        eligibleProductTypes: ['N10'],
        width: {
            value: 1850,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1000,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-310 C',
        eligibleProductTypes: ['N10'],
        width: {
            value: 1850,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1335,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-510 A',
        eligibleProductTypes: ['N10'],
        width: {
            value: 1850,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1115,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-510 B',
        eligibleProductTypes: ['N10'],
        width: {
            value: 1850,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1670,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-510 C',
        eligibleProductTypes: ['N10'],
        width: {
            value: 1850,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2230,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-311 A',
        eligibleProductTypes: ['N15'],
        width: {
            value: 2500,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 900,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-311 B',
        eligibleProductTypes: ['N15'],
        width: {
            value: 2500,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1350,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-311 C',
        eligibleProductTypes: ['N15'],
        width: {
            value: 2500,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1800,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-511 A',
        eligibleProductTypes: ['N15'],
        width: {
            value: 2500,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1500,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-511 B',
        eligibleProductTypes: ['N15'],
        width: {
            value: 2500,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2250,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-511 C',
        eligibleProductTypes: ['N15'],
        width: {
            value: 2500,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3000,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-312 A',
        eligibleProductTypes: ['N20'],
        width: {
            value: 3000,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1080,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-312 B',
        eligibleProductTypes: ['N20'],
        width: {
            value: 3000,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1620,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-312 C',
        eligibleProductTypes: ['N20'],
        width: {
            value: 3000,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2160,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-512 A',
        eligibleProductTypes: ['N20'],
        width: {
            value: 3000,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1800,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-512 B',
        eligibleProductTypes: ['N20'],
        width: {
            value: 3000,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2700,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-512 C',
        eligibleProductTypes: ['N20'],
        width: {
            value: 3000,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3600,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-313 A',
        eligibleProductTypes: ['N27'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 1368,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-313 B',
        eligibleProductTypes: ['N27'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2050,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-313 C',
        eligibleProductTypes: ['N27'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 300,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2736,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-513 A',
        eligibleProductTypes: ['N27'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 2280,
            unitOfMeasurement: 'kg'  
        }
    },
    {
        code: 'T-513 B',
        eligibleProductTypes: ['N27'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 750,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 3420,
            unitOfMeasurement: 'kg'  
        }
    },
        {
        code: 'T-513 C',
        eligibleProductTypes: ['N27'],
        width: {
            value: 3800,
            unitOfMeasurement: 'mm'  
        },
        depth: {
            value: 500,
            unitOfMeasurement: 'mm'  
        },
        height: {
            value: 1000,
            unitOfMeasurement: 'mm'  
        },
        weight: {
            value: 4560,
            unitOfMeasurement: 'kg'  
        }
    },
]

export const handrailCodes: ConfigValueItem[] = [
    {
        label: 'K00',
        value: 'K00',
        shortDescription: 'Back Only / Back Only (D.Ext)',
    },
    {
        label: 'K10',
        value: 'K10',
        shortDescription: 'Back Only',
    },
    {
        label: 'K20',
        value: 'K20',
        shortDescription: 'Back +1 Level Wing (D.Ext)',
    },
    {
        label: 'K30',
        value: 'K30',
        shortDescription: 'Back +2 Full Wing',
    },
    {
        label: 'K31',
        value: 'K31',
        shortDescription: 'Back +2 Full Wing +Gate',
    },
    {
        label: 'K32',
        value: 'K32',
        shortDescription: 'Back +2 Full Wing +Gate, Walkthru',
    },
    {
        label: 'K33',
        value: 'K33',
        shortDescription: 'Back +2 Full Wing +Gate, Step Handrail',
    },
    {
        label: 'K40',
        value: 'K40',
        shortDescription: 'Back +2 Level Wing (D.Ext)',
    },
    {
        label: 'K41',
        value: 'K41',
        shortDescription: 'Back +2 Level Wing (D.Ext) +Gate',
    },
    {
        label: 'K42',
        value: 'K42',
        shortDescription: 'Back +2 Level Wing (D.Ext) +Gate, Walkthru',
    },
    {
        label: 'K43',
        value: 'K43',
        shortDescription: 'Back +2 Level Wing (D.Ext) +Gate, Step Handrail',
    },
    {
        label: 'K50',
        value: 'K50',
        shortDescription: 'Back +2 Half Wing',
    },
    {
        label: 'K51',
        value: 'K51',
        shortDescription: 'Back +2 Half Wing +Gate',
    },
    {
        label: 'K52',
        value: 'K52',
        shortDescription: 'Back +2 Half Wing +Gate, Walkthru',
    },
    {
        label: 'P10',
        value: 'P10',
        shortDescription: 'Back Only / Back Only D.Ext',
    },
    {
        label: 'P20',
        value: 'P20',
        shortDescription: 'Back +1 Level Wing (D.Ext)',
    },    
    {
        label: 'P30',
        value: 'P30',
        shortDescription: 'Back +2 Full Wing',
    },
    {
        label: 'P40',
        value: 'P40',
        shortDescription: 'Back +2 Level Wing (D.Ext)',
    },
    {
        label: 'P50',
        value: 'P50',
        shortDescription: 'Back +2 Half Wing'
    }
]

export const prettyDisplayValue = (value: string) => {
    const spaceDelimiter = ` `
    const underscoreDelimiter = `_`
    return value
        .toLowerCase()
        .split(underscoreDelimiter)
        .map((word) => word.substring(0,1).toUpperCase() + word.substring(1, word.length))
        .join(spaceDelimiter)
}

export const drawingCategories: ConfigValueItem[] = [
    {
        label: 'JKH',
        value: 'JKH'
    },
    {
        label: 'Althon',
        value: 'ALTHON'
    },
    {
        label: 'Production',
        value: 'PRODUCTION'
    },
    {
        label: 'Reinforcement',
        value: 'REINFORCEMENT'
    },
    {
        label: 'Quality Control',
        value: 'QUALITY_CONTROL'
    }
]

export const flowControlDeviceTypes: ConfigValueItem[] = [
    {
        label: 'Flap Valve',
        value: 'FLAP_VALVE'
    },
    {
        label: 'Penstock',
        value: 'PENSTOCK'
    },
    {
        label: 'Ductile Iron Flap Valve',
        value: 'DUCTILE_IRON_FLAP_VALVE'
    },
    {
        label: 'Duckbill Valve',
        value: 'DUCKBILL_VALVE'
    },
    {
        label: 'Inline Non Return Valve',
        value: 'INLINE_NON_RETURN_VALVE',
    },    
    {
        label: 'Orifice Plate',
        value: 'ORIFICE_PLATE',
    }
]

export const drawingTypes: ConfigValueItem[] = [
    {
        label: 'Toe',
        value: 'TOE'
    },
    {
        label: 'Steelwork',
        value: 'STEELWORK'
    },
    {
        label: 'Handrail',
        value: 'HANDRAIL'
    },
    {
        label: 'Secondary Casting',
        value: 'SECONDARY_CASTING'
    },
    {
        label: 'Flow Control Device',
        value: 'FLOW_CONTROL_DEVICE'
    },
]