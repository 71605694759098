import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'
import { PipeOpeningConfig } from 'pages/settings/configuration/ManagePipeOpeningsScreen'

export interface IUsePipeOpenings {
    pipeOpenings: PipeOpeningConfig[],
    getPipeOpenings: () => void
}

export const usePipeOpenings = () => {

    const [pipeOpenings, setPipeOpenings] = useState<PipeOpeningConfig[]>(null)
    const { getAccessTokenSilently  } = useAuth0()

    const getPipeOpenings = async () => {

        const accessToken: string = await getAccessTokenSilently()

        const response = await fetch(
        `${API_URL}/configuration/pipe-openings`
            , {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            }
        )
    
        const pipeOpeningsResp: PipeOpeningConfig[] = await response.json()

        const  sortedPipeOpenings = pipeOpeningsResp.sort((a, b) => a.material.localeCompare(b.material) || a.insideDiameter - b.insideDiameter);

        setPipeOpenings(sortedPipeOpenings)
    }

    return {
        pipeOpenings,
        getPipeOpenings
    }
}