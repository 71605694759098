import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const LoadingSpinner = ({ size }:{ size: SizeProp }) => (
  <FontAwesomeIcon icon={faCircleNotch} spin={true} size={size} />
)

const PageLoadSpinner = () => (
  <div className="has-text-centered">
    <p className="title is-5 has-text-centered has-text-grey"> <LoadingSpinner size='3x' /> </p>
  </div>
)

export { PageLoadSpinner }
