import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ColumnLabel from 'components/headers/table/ColumnLabel'
import { toast } from 'bulma-toast'
import { faAngleDown, faCircleNotch, faClone, faEllipsisVertical, faFaceGrinBeamSweat, faFile, faCircleCheck, faSearch, faSpinner, faTimes, faUserAstronaut, faWater, faPencil, faEye, faCopy } from '@fortawesome/free-solid-svg-icons'
// @ts-ignore
import JKHLogo from '../public/images/jkh-logo.svg'

// @ts-ignore
import JKHLogo2 from '../public/images/JKH-Logo-transparent-bg.png'

// @ts-ignore
import AddFilesImage from '../public/images/undraw_add_files.svg'

import { User } from 'ManageUsersScreen'
import { API_URL, PAID_ADD_ONS, prettyDisplayValue } from 'constants/Constants'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { Permissions } from 'AppController'
import { isMerchant } from 'models/User'
import { SearchFilters, useProductFilters } from 'hooks/useProductFilters'
import { ProductFiltersMenu } from 'components/filters/ProductFiltersMenu'
import { SearchFilter } from 'components/filters/SearchFilter'

const NoSearchResultsInfoMessage = () => (
    <div className="has-text-centered">
      <p className="title is-5 has-text-centered">Well... this is awkward <FontAwesomeIcon className="pl-2" icon={faFaceGrinBeamSweat} size='lg' /> </p>
      <p className="subtitle is-6 has-text-centered">We couldn't find what you were looking for - please alter your search and try again</p>
    </div>
)

const NoItemsAddedInfoMessage = ({searchIndex}: {searchIndex: 'products' | 'drawings'}) => {

  const buttonContext = searchIndex === `products` ? `product` : `drawing`
  const buttonLink = searchIndex === `products` ? `/add/product` : `/add/drawing`

  return(
    <div className="has-text-centered">
        <p className="title is-5 has-text-centered">{`You haven't added a ${buttonContext} yet`}</p>
        <p className="subtitle is-6 has-text-centered">Welcome - lets get started</p>
        <Link to={buttonLink}><button className="button is-outlined">{`Add a ${buttonContext}`}</button></Link>
    </div>
  )
}

const SearchBarHero = ({
  onChangeSearchQuery,
  searchQuery,
  onClickSearch,
  onSelectSearchIndex,
  selectedSearchIndex,
  loggedInUser,
}: {
  onChangeSearchQuery: (e: React.ChangeEvent<HTMLInputElement>) => void,
  searchQuery: string,
  onClickSearch: () => void,
  onSelectSearchIndex: (index: `products` | `drawings`) => void,
  selectedSearchIndex: 'products' | 'drawings',
  loggedInUser: User,
}) => {

  return (
  <section className="hero is-medium"> 

    <div className="hero-body pb-6">
      <div className="container has-text-centered">


        <img src={JKHLogo2} alt="JKH logo." width={'10%'} height={'10%'} />

        <p className="title">
          Find your product
        </p>
        <p className="subtitle">
          All of the products you're looking for, searchable in one place.
        </p>

        <div className="field has-addons">
        <div className="control has-icons-left is-expanded">
          <input 
            className="input is-rounded is-medium" 
            type="text" 
            onChange={onChangeSearchQuery} 
            onKeyPress={event => event.key === 'Enter' && onClickSearch()} 
            value={searchQuery} 
            autoFocus={true} />
        </div>
          <div className="control">
            <a className="button is-info is-light is-outlined is-medium is-rounded" onClick={onClickSearch}>
              Search
            </a>
          </div>
        </div>

        
        {!isMerchant(loggedInUser) &&
          <div className="field">
            <input className="is-checkradio is-info" id="productsCheckRadio" type="radio" name="productsSearchable" checked={selectedSearchIndex === 'products'} onChange={() => {}} value={`products`} />
            <label htmlFor="productsSearchable" onClick={() => onSelectSearchIndex('products')}>Products</label>
            <input className="is-checkradio is-info" id="drawingsCheckRadio" type="radio" name="drawingsSearchable" checked={selectedSearchIndex === 'drawings'} onChange={() => {}} value={`drawings`} />
            <label htmlFor="drawingsSearchable" onClick={() => onSelectSearchIndex('drawings')}>Drawings</label>
          </div>
        }

      </div>
    </div>
  </section>
  )
}

export interface PipeOpening {
  pipeId: string,
  insideDiameter?: number,
	material?: string,
	outsideDiameter?: number, 
	openingSize?: number,
  invertLevel?: number,
  openingLevel?: number,
  height?: number,
  width?: number
}

export interface FlowControlDevice {
  size: number,
  type: string
}

interface AssetInfo {
  assetUrl: string,
  assetFileName: string,
  assetFileExtension: string,
  assetPath: string,
}

export interface DesignDocumentResponse {
  category: string,
  asset: AssetInfo
}

export type MetadataStatusType = 'PENDING_REVIEW' | 'EDITS_REQUESTED' | 'APPROVED'

export interface Metadata {
  status: MetadataStatusType,
  version?: number,
  created?: {
    byUserId: string,
    byFullName: string,
    timestamp: string,
  },
  lastEdited?: {
    byUserId: string,
    byFullName: string,
    timestamp: string, 
    comment?: string,
  },  
  lastModerated?: {
    byUserId: string,
    byFullName: string,
    timestamp: string,
    comment?: string,
  }
}

export interface ProductSearchResult {
  objectID: string,
  productCode: string,
  description: string,
  flowControlDevices: FlowControlDevice[],
  secondaryCasting: string[],
  productType: string,
  toe: string,
  steelworkCode: string[],
  handrail: string,
  pipeOpenings: PipeOpening[],
  designDocuments: DesignDocumentResponse[],
  tags: string[],
  metadata?: Metadata
}

export type DrawingType = 'steelwork' | 'toe' | 'handrail' | 'secondary_casting' | 'flow_control_device' | ''

export type DrawingSearchResult = {
  objectID: string,
  code: string,
  drawingCode?: string,
  description: string,
  type: DrawingType,
  weight: number,
  tags: string[],
  designDocuments: DesignDocumentResponse[],
  metadata?: Metadata
}

export interface IProductSearchResponse {
  query: string,
  pages: number,
  page: number,
  pageSize: number,
  noOfResults: number,
  results: ProductSearchResult[],
  facets: Record<string, Record<string, number>>
}

export interface IDrawingSearchResponse {
  query: string,
  pages: number,
  page: number,
  pageSize: number,
  noOfResults: number,
  results: DrawingSearchResult[],
  facets: Record<string, Record<string, number>>
}

const ProductSearchResultsHeader = () => {

  return (
    <div className="columns is-vcentered" style={{paddingLeft: 15, paddingRight:15}}>
      <div className="column is-3">   
        <ColumnLabel label={`Product`} />
      </div>
      <div className="column">
        <ColumnLabel label={`Steelwork Code`} />
      </div>
      <div className="column">
        <ColumnLabel label={`Handrail`} />
      </div>
      <div className="column">
        <ColumnLabel label={`Product Type`} />
      </div>
      <div className="column">
        <ColumnLabel label={`Toe`} />
      </div>
      <div className="column is-2">
        <ColumnLabel label={`Drawings`} />
      </div>
      <div className="column is-narrow">
        {/* Leave space for actions button. */}
      </div>
  </div>
  )
}

const DrawingSearchResultsHeader = () => {

  return (
    <div className="columns is-vcentered" style={{paddingLeft: 15, paddingRight:15}}>
      <div className="column is-3">   
        <ColumnLabel label={`Drawing`} />
      </div>
      <div className="column is-2">
        <ColumnLabel label={`Type`} />
      </div>
      <div className="column is-2">
        <ColumnLabel label={`Drawings`} />
      </div>
      <div className="column is-narrow">
        {/* Leave space for actions button. */}
      </div>
  </div>
  )
}

const ProductSearchResult = ({loggedInUser, isEditable = false, searchResult}: {loggedInUser: User, isEditable?: boolean, searchResult: ProductSearchResult}) => {

  const designDocuments: DesignDocumentResponse[] = isMerchant(loggedInUser) ? searchResult.designDocuments.filter(_ => _.category === 'ALTHON') : searchResult.designDocuments

  const onCopyProductCode = () => {
    navigator.clipboard.writeText(searchResult.productCode)

    toast({
        message: `Copied to clipboard`,
        type: 'is-info',
        position: 'bottom-right',
      })
}
  
  return(
  <div className="columns is-vcentered" style={{ borderRadius: 5, backgroundColor: 'white', marginBottom: 20, padding: 15}}>
      <div className="column is-3">   
        <div className="columns is-vcentered">
          <div className="column is-narrow">
            <FontAwesomeIcon icon={faFile} size='2x' />        
          </div>
          <div className="column">
            <p>
              <Link to={`/edit/product/${searchResult.productCode}`} className="has-text-weight-semibold title is-6" >
                {searchResult.productCode}
              </Link>
              <span className="icon is-small is-right has-text-dark is-clickable" onClick={onCopyProductCode}>
                  <FontAwesomeIcon icon={faClone} className="pl-3" />
              </span>
            </p>
            <p className="subtitle is-7"><span className="has-tooltip-arrow has-tooltip-multiline" data-tooltip={searchResult.description}>{searchResult.description.length > 40 ? `${searchResult.description.substring(0,39)}...` : searchResult.description}</span></p> 
          </div>
        </div>     
      </div>
      <div className="column">
        {searchResult.steelworkCode.length > 0 
          ?
          searchResult.steelworkCode.map((code, idx) => (
            <Link key={`steelwork-code-tag-${idx}`} to={`/view/drawing/${code}`} target='_blank'>
              <span className="tag is-underlined">
                {code}
              </span>
            </Link>
          ))
          :
          `--`
        }
      </div>
      <div className="column">
        {searchResult.handrail || `--`}
      </div>
      <div className="column">
        {searchResult.productType}
      </div>
      <div className="column">
        {searchResult.toe || `--`}
      </div>
      <div className="column is-2">

        {designDocuments.length > 0 
        ?
        <div className="dropdown is-hoverable">
          <div className="dropdown-trigger">
            <a className="button is-text pl-0" aria-haspopup="true" aria-controls="dropdown-menu">
              <span><FontAwesomeIcon className="pr-1" icon={faFile} />{`${designDocuments.length === 1 ? `1 drawing` : `${designDocuments.length} drawings`}`} <FontAwesomeIcon className="pl-1" icon={faAngleDown} size={`sm`} /> </span>
            </a>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {designDocuments.map((doc, idx) => (
                  <a key={`category-${idx}`} target="_blank" href={doc.asset.assetUrl} className="dropdown-item">
                    {prettyDisplayValue(doc.category)}
                  </a>
              ))}
            </div>
          </div>
        </div>
        :
        <span>--</span>
        }

      </div>

      <div className="column is-narrow">
        <div className="dropdown is-hoverable">
        <div className="dropdown-trigger pl-3 pr-3 is-clickable">
            <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu-product-actions" icon={faEllipsisVertical} size={`lg`} />
        </div>
            <div className="dropdown-menu" id="dropdown-menu-product-actions" role="menu">
              <div className="dropdown-content">
                <Link to={`/edit/product/${searchResult.productCode}`} className="button is-text dropdown-item">{isEditable ? <span><FontAwesomeIcon className="pr-2" icon={faPencil} />{`View/Edit`}</span> : <span><FontAwesomeIcon className="pr-2" icon={faEye} />{`View`}</span>}</Link>
                <hr className="navbar-divider"/>
                {Permissions.canAdd(loggedInUser) && <Link to={`/add/product`} state={{ duplicateState: searchResult }} className="button is-text dropdown-item">{<span><FontAwesomeIcon className="pr-2" icon={faCopy} />{`Duplicate`}</span>}</Link> }
              </div>
            </div>
          </div>
      </div>
  </div>
  )
}

const DrawingSearchResult = ({loggedInUser, isEditable = false, searchResult}: {loggedInUser: User, isEditable?: boolean, searchResult: DrawingSearchResult}) => {
  
  const onCopyCode = () => {
    navigator.clipboard.writeText(searchResult.objectID)

    toast({
        message: `Copied to clipboard`,
        type: 'is-info',
        position: 'bottom-right',
      })
}
  
  return(
  <div className="columns is-vcentered" style={{ borderRadius: 5, backgroundColor: 'white', marginBottom: 20, padding: 15}}>
      <div className="column is-3">   
        <div className="columns is-vcentered">
          <div className="column is-narrow">
            <FontAwesomeIcon icon={faFile} size='3x' />        
          </div>
          <div className="column">
            <p>
              <Link to={`/edit/drawing/${searchResult.objectID}`} className="has-text-weight-semibold title is-6" >
                {searchResult.objectID}
              </Link>
              <span className="icon is-small is-right has-text-dark is-clickable" onClick={onCopyCode}>
                  <FontAwesomeIcon icon={faClone} className="pl-3" />
              </span>
            </p>
            <p className="subtitle is-7"><span className="has-tooltip-arrow has-tooltip-multiline" data-tooltip={searchResult.description}>{searchResult.description && searchResult.description.length > 40 ? `${searchResult.description.substring(0,39)}...` : searchResult.description}</span></p> 
          </div>
        </div>     
      </div>
      <div className="column is-2">
        {prettyDisplayValue(searchResult.type)}
      </div>
      <div className="column is-2">

        {searchResult.designDocuments.length > 0
        ?
        <div className="dropdown is-hoverable">
          <div className="dropdown-trigger">
            <a className="button is-text pl-0" aria-haspopup="true" aria-controls="dropdown-menu">
              <span><FontAwesomeIcon className="pr-1" icon={faFile} />{`${searchResult.designDocuments.length === 1 ? `1 drawing` : `${searchResult.designDocuments.length} drawings`}`} <FontAwesomeIcon className="pl-1" icon={faAngleDown} size={`sm`} /> </span>
            </a>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {searchResult.designDocuments.map((doc, idx) => (
                  <a key={`category-${idx}`} target="_blank" href={doc.asset.assetUrl} className="dropdown-item">
                    {prettyDisplayValue(doc.category)}
                  </a>
              ))}
            </div>
          </div>
        </div>
        :
        <span className="is-italic">None</span>
        }

      </div>
      <div className="column">
        <Link to={`/edit/drawing/${searchResult.objectID}`} className="button is-text is-pulled-right">{isEditable ? `View/Edit` : `View`}</Link>
      </div>
  </div>
  )
}

const SearchScreen = ({ loggedInUser }: { loggedInUser: User }) => {

  const { getAccessTokenSilently  } = useAuth0()
  const { isRefresh } = useParams()

  const [selectedSearchIndex, setSelectedSearchIndex] = useState<`products` | `drawings`>(`products`)
  const [productSearchResponse, setProductSearchResponse] = useState<IProductSearchResponse>()
  const [drawingSearchResponse, setDrawingSearchResponse] = useState<IDrawingSearchResponse>()
  const [searchError, setSearchError] = useState<string>()
  const [isSearchQueryDirty, setIsSearchQueryDirty] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const { productFilters, onCheckFilter: onCheckProductFilter, onCheckNoneFilter, onClearFilters: onClearProductFilters } = useProductFilters()

  const [drawingFilters, setDrawingFilters] = useState<SearchFilters>({
    type: {
      appliedFilters: []
    },
    category: {
      appliedFilters: []
    }
  })

  // TODO - make a hook - repeated in manageProducts
  const searchProductsApi = async (query: string, filters?: SearchFilters, page?: number): Promise<Response> => {

    const pageParam: string = page ? `&page=${page}` : ``

    const accessToken: string = await getAccessTokenSilently()

    if (filters) {
      const steelworkCodeFilters: string = filters && filters.steelworkCode && filters.steelworkCode.appliedFilters.length > 0  ? `&steelworkCode=${filters.steelworkCode.appliedFilters.join(',')}` : ''
      const handrailFilters: string = filters && filters.handrail && filters.handrail.appliedFilters.length > 0  ? `&handrail=${filters.handrail.appliedFilters.join(',')}` : ''
      const productTypeFilters: string = filters && filters.productType && filters.productType.appliedFilters.length > 0  ? `&productType=${filters.productType.appliedFilters.join(',')}` : ''
      const toeFilters: string = filters && filters.toe && filters.toe.appliedFilters.length > 0  ? `&toe=${filters.toe.appliedFilters.join(',')}` : ''
      const secondaryCastingFilters: string = filters && filters.secondaryCasting && filters.secondaryCasting.appliedFilters.length > 0  ? `&secondaryCasting=${filters.secondaryCasting.appliedFilters.join(',')}` : ''
     
      const flowControlDeviceSizeFilters: string = filters && filters.flowControlDeviceSize && filters.flowControlDeviceSize.appliedFilters.length > 0  ? `&flowControlDevices.size=${filters.flowControlDeviceSize.appliedFilters.join(',')}` : ''
      const flowControlDeviceTypeFilters: string = filters && filters.flowControlDeviceType && filters.flowControlDeviceType.appliedFilters.length > 0  ? `&flowControlDevices.type=${filters.flowControlDeviceType.appliedFilters.join(',')}` : ''

      const invertLevelFilters: string = filters && filters.invertLevel && filters.invertLevel.appliedFilters.length > 0  ? `&pipeOpenings.invertLevel=${filters.invertLevel.appliedFilters.join(',')}` : ''
      const openingLevelFilters: string = filters && filters.openingLevel && filters.openingLevel.appliedFilters.length > 0  ? `&pipeOpenings.openingLevel=${filters.openingLevel.appliedFilters.join(',')}` : ''
      const insideDiameterFilters: string = filters && filters.insideDiameter && filters.insideDiameter.appliedFilters.length > 0  ? `&pipeOpenings.insideDiameter=${filters.insideDiameter.appliedFilters.join(',')}` : ''
      const pipeMaterialFilters: string = filters && filters.pipeMaterial && filters.pipeMaterial.appliedFilters.length > 0  ? `&pipeOpenings.material=${filters.pipeMaterial.appliedFilters.join(',')}` : ''

      const hasHandrailFilter: string = filters?.handrail?.hasNoneSelected ? `&hasHandrail=false` : ``
      const hasSteelworkCodeFilter: string = filters?.steelworkCode?.hasNoneSelected ? `&hasSteelwork=false` : ``
      const hasToeFilter: string = filters?.toe?.hasNoneSelected ? `&hasToe=false` : ``
      const hasSecondaryCastingFilter: string = filters?.secondaryCasting?.hasNoneSelected ? `&hasSecondaryCasting=false` : ``
      const hasFlowControlDeviceFilter: string = (filters?.flowControlDeviceSize?.hasNoneSelected || filters?.flowControlDeviceType?.hasNoneSelected) ? `&hasFlowControlDevice=false` : ``
      const hasPipeOpeningFilter: string = (filters?.invertLevel?.hasNoneSelected || filters?.openingLevel?.hasNoneSelected || filters?.insideDiameter?.hasNoneSelected || filters?.pipeMaterial?.hasNoneSelected) ? `&hasPipeOpenings=false` : ``
      
      const statusFilter = (): string => PAID_ADD_ONS.quarantineZone.isEnabled ? `&metadata.status=APPROVED` : ``

      return fetch(
        `${API_URL}/products/search?q=${query}${pageParam}${steelworkCodeFilters}${handrailFilters}${productTypeFilters}${insideDiameterFilters}${pipeMaterialFilters}${toeFilters}${flowControlDeviceSizeFilters}${flowControlDeviceTypeFilters}${secondaryCastingFilters}${invertLevelFilters}${openingLevelFilters}${statusFilter()}${hasHandrailFilter}${hasSteelworkCodeFilter}${hasToeFilter}${hasSecondaryCastingFilter}${hasFlowControlDeviceFilter}${hasPipeOpeningFilter}`
          , {
            method: 'GET',
            redirect: 'follow',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": 'application/json'
          },
          }); 
    } else {
      return fetch(
        `${API_URL}/products/search?q=${query}${pageParam}`
          , {
            method: 'GET',
            redirect: 'follow',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": 'application/json'
          },
          }); 
    }
  }

  const searchDrawingsApi = async (query: string, filters?: SearchFilters, page?: number): Promise<Response> => {

    const pageParam: string = page ? `&page=${page}` : ``

    const accessToken: string = await getAccessTokenSilently()

    if (filters) {
      const typeFilters: string = filters && filters.type && filters.type.appliedFilters.length > 0  ? `&type=${filters.type.appliedFilters.join(',')}` : ''
      const categoryFilters: string = filters && filters.category && filters.category.appliedFilters.length > 0  ? `&category=${filters.category.appliedFilters.join(',')}` : ''

      return fetch(
        `${API_URL}/drawings/search?q=${query}${pageParam}${typeFilters}${categoryFilters}`
          , {
            method: 'GET',
            redirect: 'follow',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": 'application/json'
          },
          }); 
    } else {
      return fetch(
        `${API_URL}/drawings/search?q=${query}${pageParam}`
          , {
            method: 'GET',
            redirect: 'follow',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": 'application/json'
          },
          }); 
    }
  }


  useEffect(() => {

    const search = async () => {

      if (selectedSearchIndex === 'products') {
  
        const response = await searchProductsApi(searchQuery, productFilters)
        const searchResponse = await response.json()
        setProductSearchResponse(searchResponse)
     
      } else {
        const response = await searchDrawingsApi(searchQuery, drawingFilters)
        const searchResponse = await response.json()
        setDrawingSearchResponse(searchResponse)
      }
  
    }

    search()
    .catch((error) => setSearchError(error))
  }, [productFilters, drawingFilters, selectedSearchIndex, isRefresh])

  const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.currentTarget.value)
    setIsSearchQueryDirty(true)
  }

  const onChangeSearchIndex = (index: `products` | `drawings`) => {
      setSearchQuery('')
      setIsSearchQueryDirty(false)
      setSelectedSearchIndex(index)
  }

  const onClickSearch = () => {
        
    const search = async () => {

      if (selectedSearchIndex === 'products') {
        const response = await searchProductsApi(searchQuery, productFilters)
        const searchResponse = await response.json()
        setProductSearchResponse(searchResponse)
      
      } else {
        const response = await searchDrawingsApi(searchQuery, drawingFilters)
        const searchResponse = await response.json()
        setDrawingSearchResponse(searchResponse)
        
      }

      setIsSearchQueryDirty(false)
    }

    search()
      .catch((error) => setSearchError(error))
  }

  const onClearSearchQuery = () => {
    setSearchQuery('')

    const search = async () => {
      if (selectedSearchIndex === 'products') {
        const response = await searchProductsApi('', productFilters)
        const searchResponse = await response.json()
        setProductSearchResponse(searchResponse)

      } else {
        const response = await searchDrawingsApi('', drawingFilters)
        const searchResponse = await response.json()
        setDrawingSearchResponse(searchResponse)
      }

      setIsSearchQueryDirty(false)
    }

    search()
      .catch((error) => setSearchError(error))
  }

  const emptyAppliedFiltersState = (searchFilters: SearchFilters) => {
    return Object.fromEntries(Object.entries(searchFilters).map(([key, value]) => {
      return [key, {
        ...value,
        appliedFilters: [] 
      }]
    }))
  }

  const onClearFilters = () => {
      onClearProductFilters()

      const emptyAppliedDrawingFilters: SearchFilters = emptyAppliedFiltersState(drawingFilters)
      setDrawingFilters(emptyAppliedDrawingFilters)
  }

  const onCheckDrawingFilter = (e: React.ChangeEvent<HTMLInputElement>, facet: string, facetValue: string) => {
    setDrawingFilters({
      ...drawingFilters,
      [facet]: {
        ...drawingFilters[facet],
        appliedFilters: e.currentTarget.checked ? [...drawingFilters[facet].appliedFilters, facetValue] : drawingFilters[facet].appliedFilters.filter(_ => _ !== facetValue)
      }
    })
  }

  const onClickPage = (pageNo: number, isDisabled: boolean) => {
    
    const search = async () => {
      if (selectedSearchIndex === 'products') {
        const response = await searchProductsApi(searchQuery, productFilters, pageNo)
        const searchResponse = await response.json()
        setProductSearchResponse(searchResponse)

      } else {
        const response = await searchDrawingsApi(searchQuery, drawingFilters, pageNo)
        const searchResponse = await response.json()
        setDrawingSearchResponse(searchResponse)
      }

      setIsSearchQueryDirty(false)
    }
 
    !isDisabled && 
      search()
        .catch((error) => setSearchError(error))

  }

  const displayPrettyFilter = (facet: Record<string, number>) => {
    return Object.fromEntries(Object.entries(facet).map( ([facetValue, count]) =>  ( [prettyDisplayValue(facetValue), count] )  ))
  }

    return(
      <>  
        <SearchBarHero 
          onChangeSearchQuery={onChangeSearchQuery} 
          searchQuery={searchQuery} 
          onClickSearch={onClickSearch} 
          onSelectSearchIndex={onChangeSearchIndex}
          selectedSearchIndex={selectedSearchIndex}
          loggedInUser={loggedInUser} />
        <div className="container is-fullheight">

          {selectedSearchIndex === 'products'
          ?
          <div className="columns mb-2">
            {productSearchResponse &&
            <div className="column is-narrow pr-0">
                <button className={`button is-rounded ${productSearchResponse.query === '' ? `is-static` : ''}`} onClick={onClearSearchQuery} >
                  <span>
                    <FontAwesomeIcon className='pr-2' icon={faSearch} size="lg" />
                    {`Search: "${productSearchResponse.query}"`}
                    {productSearchResponse.query !== '' && <FontAwesomeIcon className='pl-2' icon={faTimes} /> }
                  </span>
                  </button> 
            </div>
            }
            {Object.values(productFilters).some(_ => _.appliedFilters.length > 0 || _.hasNoneSelected) &&
              <div className="column is-narrow pl-0">
                <button className="button is-text" aria-haspopup="true" aria-controls="dropdown-menu" onClick={onClearFilters}>
                  <span>Clear filters <FontAwesomeIcon className='pl-2' icon={faTimes} /></span>
                </button>
              </div>
            }
          </div>
          :
          <div className="columns mb-2">
            {drawingSearchResponse &&
            <div className="column is-narrow pr-0">
                <button className={`button is-rounded ${drawingSearchResponse.query === '' ? `is-static` : ''}`} onClick={onClearSearchQuery} >
                  <span>
                    <FontAwesomeIcon className='pr-2' icon={faSearch} size="lg" />
                    {`Search: "${drawingSearchResponse.query}"`}
                    {drawingSearchResponse.query !== '' && <FontAwesomeIcon className='pl-2' icon={faTimes} /> }
                  </span>
                  </button> 
            </div>
            }
            {Object.values(drawingFilters).some(_ => _.appliedFilters.length > 0) &&
              <div className="column is-narrow pl-0">
                <button className="button is-text" aria-haspopup="true" aria-controls="dropdown-menu" onClick={onClearFilters}>
                  <span>Clear filters <FontAwesomeIcon className='pl-2' icon={faTimes} /></span>
                </button>
              </div>
            }
          </div>
          }

          {selectedSearchIndex === 'products'
          ?
          <>

          <ProductFiltersMenu
              productSearchResponse={productSearchResponse}
              productFilters={productFilters}
              onCheckProductFilter={onCheckProductFilter}
              onCheckNoneFilter={onCheckNoneFilter} />

          {productSearchResponse && productSearchResponse.noOfResults > 0 &&
            <div className="columns">
              <div className="column">
                <span className="title is-5">{`${productSearchResponse.noOfResults} product${productSearchResponse.noOfResults > 1 ? `s` : ``} found`}</span>
              </div>
            </div>
          }

          { productSearchResponse && productSearchResponse.noOfResults > 0 ?
          <>
            <ProductSearchResultsHeader />
            {productSearchResponse.results.map((result) => (<ProductSearchResult loggedInUser={loggedInUser} isEditable={Permissions.canEdit(loggedInUser)} searchResult={result} key={result.productCode} />))}
            <nav className="pagination is-centered mb-6" role="navigation" aria-label="pagination">
              <a onClick={() => onClickPage(productSearchResponse.page -1, productSearchResponse.page === 0)} className={`pagination-previous ${productSearchResponse.page === 0 ? `is-disabled` : ``}`}>Previous</a>
              <a onClick={() => onClickPage(productSearchResponse.page +1, productSearchResponse.page === productSearchResponse.pages - 1)} className={`pagination-next ${productSearchResponse.page === productSearchResponse.pages - 1 ? `is-disabled` : ``}`}>Next page</a>
              <ul className="pagination-list">
                {Array.from(Array(productSearchResponse.pages).keys()).map((pageNo: number) => (
                  <li onClick={() => onClickPage(pageNo, false)} key={`page-num-${pageNo}`}><a className={`pagination-link ${productSearchResponse.page === pageNo ? `is-current` : ``}`} aria-label={`Goto page ${pageNo}`}>{pageNo + 1}</a></li>
                ))
                }
              </ul>
            </nav>
          </>
          : productSearchResponse && productSearchResponse.noOfResults == 0 && (searchQuery === '' || searchQuery === undefined) && Object.entries(productFilters).every(([_, value]) => (value.appliedFilters.length == 0))  ?
            <NoItemsAddedInfoMessage searchIndex={selectedSearchIndex} />
          : productSearchResponse && productSearchResponse.noOfResults == 0 ?
            <NoSearchResultsInfoMessage />
          :
          <PageLoadSpinner />
          }
          </>
          :
          <>
          <div className="columns mb-6 is-multiline">

            {drawingSearchResponse && drawingSearchResponse.facets && drawingSearchResponse.facets.type &&
                <div className="column is-narrow">
                  <SearchFilter
                    filterLabel={`Type`}
                    filterName={`type`}
                    facet={drawingSearchResponse.facets.type}
                    searchFilterSelection={drawingFilters.type}
                    onCheckFilter={onCheckDrawingFilter}
                    displayPretty={true} />
                </div>
            }

            {drawingSearchResponse && drawingSearchResponse.facets && drawingSearchResponse.facets.category &&
                <div className="column is-narrow">
                  <SearchFilter
                    filterLabel={`Category`}
                    filterName={`category`}
                    facet={drawingSearchResponse.facets.category}
                    searchFilterSelection={drawingFilters.category}
                    onCheckFilter={onCheckDrawingFilter} />
                </div>
            }

          </div>

          {drawingSearchResponse && drawingSearchResponse.noOfResults > 0 &&
            <div className="columns">
              <div className="column">
                <span className="title is-5">{`${drawingSearchResponse.noOfResults} drawing${drawingSearchResponse.noOfResults > 1 ? `s` : ``} found`}</span>
              </div>
            </div>
          }

          { drawingSearchResponse && drawingSearchResponse.noOfResults > 0 ?
          <>
            <DrawingSearchResultsHeader />
            {drawingSearchResponse.results.map((result) => (<DrawingSearchResult loggedInUser={loggedInUser} isEditable={Permissions.canEdit(loggedInUser)} searchResult={result} key={result.objectID} />))}
            <nav className="pagination is-centered mb-6" role="navigation" aria-label="pagination">
              <a onClick={() => onClickPage(drawingSearchResponse.page -1, drawingSearchResponse.page === 0)} className={`pagination-previous ${drawingSearchResponse.page === 0 ? `is-disabled` : ``}`}>Previous</a>
              <a onClick={() => onClickPage(drawingSearchResponse.page +1, drawingSearchResponse.page === drawingSearchResponse.pages - 1)} className={`pagination-next ${drawingSearchResponse.page === drawingSearchResponse.pages - 1 ? `is-disabled` : ``}`}>Next page</a>
              <ul className="pagination-list">
                {Array.from(Array(drawingSearchResponse.pages).keys()).map((pageNo: number) => (
                  <li onClick={() => onClickPage(pageNo, false)} key={`page-num-${pageNo}`}><a className={`pagination-link ${drawingSearchResponse.page === pageNo ? `is-current` : ``}`} aria-label={`Goto page ${pageNo}`}>{pageNo + 1}</a></li>
                ))
                }
              </ul>
            </nav>
          </>
          : drawingSearchResponse && drawingSearchResponse.noOfResults == 0 && (searchQuery === '' || searchQuery === undefined) && Object.entries(drawingFilters).every(([_, value]) => (value.appliedFilters.length == 0))  ?
          <NoItemsAddedInfoMessage searchIndex={selectedSearchIndex} />
          : drawingSearchResponse && drawingSearchResponse.noOfResults == 0 ?
          <NoSearchResultsInfoMessage />
          :
          <PageLoadSpinner />
          }
          </>
          }
        </div>
      </>
    )
  }

  export { SearchScreen }