import React, { useEffect, useState } from 'react'

import { useNavigate, useParams, useLocation, Location } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, faDownload, faEllipsisVertical, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'bulma-toast'
import isEqual from 'lodash.isequal'

import { User } from 'ManageUsersScreen'
import { ProductSearchResult, PipeOpening, FlowControlDevice, Metadata, MetadataStatusType } from 'SearchScreen'
import { API_URL, DATE_TIME_FORMAT, PAID_ADD_ONS } from 'constants/Constants'
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { useProductForm } from 'hooks/useProductForm'
import { ProductForm } from 'components/forms/ProductForm'
import { DesignDocument, useDesignDocumentsForm } from 'hooks/useDesignDocumentsForm'
import { usePipeOpenings } from 'hooks/usePipeOpenings'
import { usePipeMaterials } from 'hooks/usePipeMaterials'
import { useProductTypes } from 'hooks/useProductTypes'
import { Permissions } from 'AppController'
import { FormModal } from 'components/modals/FormModal'
import { TextAreaInputField } from 'components/inputs/TextAreaInputField'
import { ProductionAndQualityChecklist, productToChecklistData } from 'components/PDF/ProductionAndQualityChecklist'
import { isMerchant } from 'models/User'


const getApi = (productCode: string, accessToken: string): Promise<Response> => {
    return fetch(
    `${API_URL}/products/get/${productCode}`
        , {
        method: 'GET',
        redirect: 'follow',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": 'application/json'
        },
        }); 
  }

export interface IndexProductDocument {
    objectID: string,
    productCode: string,
    description: string,
    productType: string,
    flowControlDevices: FlowControlDevice[],
    secondaryCasting: string[],
    toe: string,
    steelworkCode: string[],
    handrail: string,
    pipeOpenings: PipeOpening[],
    designDocuments: DesignDocument[],
    tags: string[],
    metadata: Metadata
}

export interface FormInputError {
    isError: boolean,
    errorMessage: string
}

type ReactRouterLocationState = {
    duplicateState: ProductSearchResult,
    from: 'EDITS_REQUESTED',
}

const initialRequestEditsForm = {comment: ''}

interface RequestEditsForm {
  comment?: string
}

const PENDING_REVIEW: MetadataStatusType = 'PENDING_REVIEW'
const APPROVED: MetadataStatusType = 'APPROVED'
const EDITS_REQUESTED: MetadataStatusType = 'EDITS_REQUESTED'

const IndexProductScreen = ({ loggedInUser, pageState = 'add'}:{ loggedInUser: User, pageState?: 'add' | 'edit' | 'view'}) => {
        
    const { productCode } = useParams()
    const { getAccessTokenSilently  } = useAuth0()
    const navigate = useNavigate()

    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [isRequestedEditsModalActive, setIsRequestedEditsModalActive] = useState(false)
    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
    const [editObject, setEditObject] = useState<ProductSearchResult>(null)
    const [requestedEditsForm, setRequestedEditsForm] = useState<RequestEditsForm>(initialRequestEditsForm)

    const productForm = useProductForm() 
    
    const { 
        validateForm: validateProductForm, 
        formState: productFormState, 
        loadFormState: loadProductFormState,
        resetFormState: resetProductFormState,
        buildProductCode,
        initialFlowControlDevice,
        initialPipeOpening,
    } = productForm

    const designDocumentsForm = useDesignDocumentsForm() 
    const { 
        validateForm: validateDesignDocumentsForm, 
        formState: designDocumentsState, 
        loadFormState: loadDesignDocumentsFormState,
        resetFormState: resetDesignDocumentsState
     } = designDocumentsForm

    const { pipeOpenings, getPipeOpenings } = usePipeOpenings()
    const { pipeMaterials, getPipeMaterials } = usePipeMaterials()
    const { productTypes, getProductTypes, getProductType } = useProductTypes()

    // We use location to pass a duplicate state to this component for the user action of duplicating a product.
    // We then load this into the form state, but with a new product code (by using a new generated id rather that one
    // from previous product as we want it to be unique.)
    const location: Location = useLocation()
    const locationState: ReactRouterLocationState | null = location.state as ReactRouterLocationState

    const resetAllState = () => {
        resetProductFormState()
        setEditObject(null)
        resetDesignDocumentsState()
    }

    const initialiseEditObject = (editObject: ProductSearchResult, isDuplicate: boolean = false) => {
        
        setEditObject(editObject)
        loadProductFormState({
            ...editObject,
            ...(isDuplicate && { productCode: buildProductCode(editObject.productType) })
        })
        loadDesignDocumentsFormState(editObject.designDocuments)
    }

    useEffect(() => {      
          getProductTypes()
        //   .catch((error) => setSearchError(error))    
    }, [])

    useEffect(() => {      
          getPipeOpenings()
        //   .catch((error) => setSearchError(error))    
    }, [])

    useEffect(() => {
          getPipeMaterials()
        //   .catch((error) => setSearchError(error))    
    }, [])

    useEffect(() => {

        if (pageState !== 'add') {
            const get = async (id: string) => {

                const accessToken: string = await getAccessTokenSilently()
                const response = await getApi(id, accessToken)
                const getResponse: ProductSearchResult = await response.json()

                if (!response.ok) {
                    navigate(`/`)
                }

                initialiseEditObject(getResponse)
            }

            get(productCode)
        } else {
            resetAllState()
        }
        
    }, [pageState !== 'add'])

    // We use this effect to load the duplicate state (from a user requesting to duplicate a product) into the form state
    // making sure to generate a new id so it's unique.
    useEffect(() => {
        if (locationState && locationState.duplicateState) {

            initialiseEditObject(locationState.duplicateState, true)

        } else {
            resetAllState()
        }
    }, [pageState === 'add', locationState && locationState.duplicateState])


    const buildMetadata = (pageState: 'add' | 'edit' | 'view'): Metadata => {

        const newStatus: MetadataStatusType = PAID_ADD_ONS.quarantineZone.isEnabled ? PENDING_REVIEW : APPROVED

        const metadata = {
            byUserId: loggedInUser.user_id,
            byFullName: loggedInUser.name,
            timestamp: moment.utc().format(DATE_TIME_FORMAT)
        }

        if (pageState === 'edit' && editObject) 
            return {
                ...editObject.metadata,
                status: newStatus,
                // if there is no created info (as products may have been created prior to the addition of metadata add it).
                ...(!editObject.metadata.created && 
                    { created: metadata }
                ),
                lastEdited: {
                    ...metadata, 
                    ...(locationState && locationState.from === EDITS_REQUESTED && {comment: requestedEditsForm.comment})
                }
            }
        else if (pageState === 'add') {

            return {
                status: newStatus,
                created: metadata,
                lastEdited: metadata
            }
        } else null
        
    }

    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm()

        if (isFormValid) {

            const metadata: Metadata = buildMetadata(pageState)

            setIsRequestLoading(true)

            const indexDoc: IndexProductDocument = {
                objectID: productFormState.productCode,
                productCode: productFormState.productCode,
                description: productFormState.description,
                productType: productFormState.productType,
                flowControlDevices: productFormState.flowControlDevices.filter(_ => !isEqual(_, initialFlowControlDevice)),
                secondaryCasting: productFormState.secondaryCasting,
                toe: productFormState.toe,
                steelworkCode: productFormState.steelworkCode,
                handrail: productFormState.handrail === `KQ` || productFormState.handrail === `PQ` ? `${productFormState.handrail}-${productFormState.productCode}` : productFormState.handrail,
                pipeOpenings: 
                productFormState.pipeOpenings.filter(_ => !isEqual(_, initialPipeOpening)),
                tags: productFormState.tags,
                designDocuments: designDocumentsState.map((doc) => ({
                    ...doc,
                    category: doc.category.category,
                    asset: {
                        assetPath: doc.asset.filePath,
                        assetFileName: doc.asset.fileName,
                        assetFileExtension: doc.asset.fileExtension
                    } 
                })),
                ...(metadata && { metadata: metadata })
            }

            const accessToken: string = await getAccessTokenSilently()

            const response = await fetch(
                `${API_URL}/products/index`
                    , {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(indexDoc)
                }); 

            if (response.status === 201) {
                toast({
                    message: `Success, product ${pageState === 'edit' ? `edited` : `added`}.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  setIsRequestLoading(false)
                  setIsRequestedEditsModalActive(false)
                  setRequestedEditsForm(initialRequestEditsForm)
                  if (pageState !== 'edit') clearState()
                  
            } else {
                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })

                setIsRequestLoading(false)
            }
        }
    }

    const validateForm = (): boolean => {
        return validateProductForm() && validateDesignDocumentsForm()
    }

    const clearState = () => {
        resetProductFormState()
        resetDesignDocumentsState()
    }

    const onDeleteProduct = async () => {

        const accessToken: string = await getAccessTokenSilently()

        setIsRequestLoading(true)

        try {
            const response = await fetch(
                `${API_URL}/products/get/${productCode}`
                    , {
                    method: 'DELETE',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: ''
                }); 
    
            if (response.status === 200) {
                toast({
                    message: `Success, product deleted.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  setIsRequestLoading(false)
                  setIsConfirmDeleteModalActive(false)
                  navigate({
                    pathname: '/',
                    search: '?isRefresh=true',
                  }, { replace: true })
                  
            } else {
                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }
        } catch (error) {
            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
              })
        }

        setIsRequestLoading(false)
    }

    const onClear = (e: React.MouseEvent) => {
        e.preventDefault()
        clearState()
    }

    const onCancelEdit = (e: React.MouseEvent) => {
        e.preventDefault()
        initialiseEditObject(editObject)
    }

    if ((pageState === 'edit'  && !editObject) || !productTypes || !pipeOpenings) return(
        <div className="container is-fullheight">
            <div className="columns mt-6 pt-6">
                <div className="column is-offset-one-quarter is-half" style={{padding: '5rem', borderRadius: 6}}>
                    <PageLoadSpinner />
                </div>
            </div>
        </div>
    )

    const onCancelRequestedEditsModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setRequestedEditsForm(initialRequestEditsForm)
        setIsRequestedEditsModalActive(false)
    }

    const onClickShowRequestedEditsModal = (e: React.MouseEvent): void => {
        e.preventDefault();
        validateForm() && setIsRequestedEditsModalActive(true)    
    }

    return (
        <div className="container is-fullheight">

            {pageState === 'edit' && Permissions.canDelete(loggedInUser) &&
                <ConfirmDeleteModal 
                    isModalActive={isConfirmDeleteModalActive}
                    isLoading={isRequestLoading}
                    onCancel={() => setIsConfirmDeleteModalActive(false)}
                    onConfirmDelete={onDeleteProduct} />
            }

            {pageState === 'edit' && locationState && locationState.from === EDITS_REQUESTED &&
              <FormModal
                isModalActive={isRequestedEditsModalActive}
                modalTitle={`Please confirm your changes`}
                onCancelModal={onCancelRequestedEditsModal}
                Form={
                    <form>
                        
                        <div className="message is-warning">
                            <div className="message-body p-5">
                            <div className="columns is-vcentered">
                                <div className="column is-narrow p-0">
                                <FontAwesomeIcon icon={faCircleUser} size={'2x'} />
                                </div>
                                <div className="column is-narrow">
                                <span className="pl-1 has-text-weight-semibold">{editObject.metadata.lastModerated.byFullName}</span> previously requested edits
                                </div>
                            </div>
                            <div className="columns is-vcentered">
                            <div className="column pt-0 pl-6">
                                {editObject.metadata.lastModerated.comment && 
                                <>
                                <span className="has-text-weight-bold is-size-4">"</span>
                                <span className="is-italic is-size-6">{editObject.metadata.lastModerated.comment}</span>
                                <span className="has-text-weight-bold is-size-4">"</span>
                                </>
                                }
                            </div>
                            </div>
                            </div>
                        </div>

                        <TextAreaInputField
                            fieldLabel={`Add a comment`}
                            onChange={(e) => setRequestedEditsForm({comment: e.target.value})}
                            fieldName={'comment'}
                            value={requestedEditsForm.comment}
                            errorValue={null}
                            isOptional={true}
                            helpText={`Edit comment`} />

                        <div className="field is-grouped is-pulled-right">
                            <div className="control">
                                <button className={`button is-link ${isRequestLoading ? `is-loading` : ``}`} disabled={designDocumentsState && designDocumentsState.every(_ => _.asset.isLoading)} onClick={onSubmitForm}>{`Submit`}</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={(e) => onCancelRequestedEditsModal(e)}>Cancel</button>
                            </div>
                        </div>

                    </form>
                } />
            }

            <div className="columns mt-6 pt-6">

            <div className="column is-offset-one-quarter is-half has-background-white p-0" style={{borderRadius: 6}}>

                {pageState !== 'add' && (Permissions.canDelete(loggedInUser) || Permissions.canDownloadProductionChecklist(loggedInUser)) &&
                    <div className="dropdown is-hoverable pt-5 pr-5 is-pulled-right">
                        <div className="dropdown-trigger">
                            <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" className="is-clickable" icon={faEllipsisVertical} size={`lg`} />
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div className="dropdown-content">
                                {Permissions.canDownloadProductionChecklist(loggedInUser) &&                                
                                    <PDFDownloadLink className="dropdown-item" document={<ProductionAndQualityChecklist checklistData={productToChecklistData(editObject, getProductType(editObject.productType))} />} fileName={`${productForm.formState.productCode}_Production_And_Quality_Control_CheckList.pdf`}>
                                        {({ blob, url, loading, error }) => (loading ? <FontAwesomeIcon icon={faSpinner} /> : <span><FontAwesomeIcon icon={faDownload} className="pr-2" /> Download Production Checklist</span>)}
                                    </PDFDownloadLink> 
                                }
                                {Permissions.canDelete(loggedInUser) && Permissions.canDownloadProductionChecklist(loggedInUser) && <hr className="navbar-divider" /> }
                                    {pageState === 'edit' && Permissions.canDelete(loggedInUser) && 
                                        <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => { e.preventDefault(); setIsConfirmDeleteModalActive(true) } }>
                                            <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                        </a>
                                    }
                            </div>
                        </div>
                    </div>
                }
               
                <div className="block pt-6 pr-6 pl-6 p-2 mb-0">
                    <h1 className="title is-3">{pageState === 'edit'  ? `Edit Product` :  pageState === 'view' ? `View Product` : `Add a new product`}</h1>   
                </div>

                <ProductForm
                    isMerchant={isMerchant(loggedInUser)}
                    formView={pageState}
                    productForm={productForm}
                    designDocumentsForm={designDocumentsForm}
                    productTypes={productTypes}
                    pipeOpenings={pipeOpenings}
                    pipeMaterials={pipeMaterials} />

                    <div className="block pt-4 pb-6 pl-6 pr-6 mb-4">
                        {pageState !== 'view' &&
                            <div className="field is-grouped is-pulled-right">
                                <div className="control">
                                    {locationState && locationState.from === EDITS_REQUESTED && pageState === 'edit'
                                    ?
                                    <button className={`button is-link`} disabled={designDocumentsState && designDocumentsState.every(_ => _.asset.isLoading)} onClick={onClickShowRequestedEditsModal}>{`Edit`}</button>
                                    :
                                    <button className={`button is-link ${isRequestLoading ? `is-loading` : ``}`} disabled={designDocumentsState && designDocumentsState.every(_ => _.asset.isLoading)} onClick={onSubmitForm}>{pageState === 'edit' ? `Edit` : `Submit`}</button>
                                    }
                                    
                                </div>
                                <div className="control">
                                    {pageState === 'edit'  ?
                                        <button className="button is-link is-light" onClick={onCancelEdit}>Cancel</button>
                                    :
                                        <button className="button is-link is-light" onClick={onClear}>Clear</button>   
                                    }
                                </div>
                            </div>
                        }
                    </div>

            </div>                    
                
            </div>
        </div>
    )
}

export { IndexProductScreen }