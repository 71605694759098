import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { API_URL } from 'constants/Constants'
import { ProductSearchResult } from 'SearchScreen'

export type OrderStatus = 'live' | 'on_hold' | 'cancelled' | 'complete'

export interface IOrderDetails {
    userId: string,
    timestamp: string,
    status: OrderStatus
}

export interface ICustomerDetails {
    merchantId: string
    merchantName: string
}

export interface IDeliveryDetails {
    companyName: string,
    postCode: string,
    telephoneNumber: string
}

interface IOrderProduct {
    product: ProductSearchResult,
    quantity: number
}

export interface IOrderResponse {
    orderId: string,
    orderDetails: IOrderDetails,
    customerDetails: ICustomerDetails,
    deliveryDetails: IDeliveryDetails,
    products: IOrderProduct[]
}

export const useOrder = (orderId: string) => {

    const [order, setOrder] = useState<IOrderResponse>(null)
    const [didOrderUpdate, setDidOrderUpdate] = useState<boolean>(false)
    const { getAccessTokenSilently  } = useAuth0()

    const getOrder = async () => {

        const accessToken: string = await getAccessTokenSilently()

        const response = await fetch(
        `${API_URL}/orders/${orderId}`
            , {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            },
            }
        )

        const orderResp: IOrderResponse = await response.json()
        setOrder(orderResp)
        setDidOrderUpdate(false)
    }

    useEffect(() => {
        getOrder()
    }, [orderId, didOrderUpdate])

    return {
        order,
        setDidOrderUpdate
    }
}