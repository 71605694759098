import React from 'react'
import { createRoot } from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { BasketProvider } from './contexts/Basket'
import { AppController } from './AppController' 

import 'styles/bulma-setup.scss'

const root = createRoot(document.getElementById('root'));

const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE;

root.render(
    <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        audience={AUTH0_AUDIENCE}
        redirectUri={window.location.origin}>
            <BasketProvider>
                <AppController />
            </BasketProvider>
    </Auth0Provider>
    
)