import React from 'react'

import { FormInputError } from 'IndexProductScreen'
import { OptionalFieldFormLabel } from './labels/OptionalFieldFormLabel'

const TextAreaInputField = ({
    fieldLabel,
    onChange,
    fieldName,
    value,
    errorValue,
    helpText,
    isDisabled = false,
    isOptional = false,
}:{
    fieldLabel: string,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    fieldName: string,
    value: string,
    errorValue: FormInputError,
    helpText: string,
    isDisabled?: boolean,
    isOptional?: boolean,
}) => (
    <div className="field pb-1">
        <label className="label">
            <span>{fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1,fieldLabel.length)}</span>
            {isOptional && <OptionalFieldFormLabel />}
        </label>
        <div className="control">
        <textarea className={`textarea ${errorValue && errorValue.isError ? `is-danger`: ''}`} name={fieldName} value={value} onChange={onChange} disabled={isDisabled}></textarea>
            {/* <p className="help">{helpText}</p> */}
            { errorValue && errorValue.isError && <p className="help is-danger">{errorValue.errorMessage}</p> }
        </div>
    </div>
)

export { TextAreaInputField }