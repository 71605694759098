import React, { useState } from 'react'

import { FormInputError } from 'IndexProductScreen'
import { OptionalFieldFormLabel } from './labels/OptionalFieldFormLabel'

const TagsInput = ({
    fieldLabel,
    fieldName,
    errorValue,
    tags,
    onAddTag,
    onDeleteTag,
    isDisabled = false,
    isOptional = false,
}:{
    fieldLabel: string,
    fieldName: string,
    errorValue: FormInputError,
    tags: string[]
    onAddTag: (tag: string) => void,
    onDeleteTag: (tag: string) => void,
    isDisabled?: boolean,
    isOptional?: boolean,
}) => {

    const [tagInputValue, setTagInputValue] = useState('')

    const onAdd = (e: React.MouseEvent) => {
        e.preventDefault()

        if (tagInputValue.length > 0 && !tags.includes(tagInputValue)) {
            onAddTag(tagInputValue)
            setTagInputValue('')
        }
    }

    const onDelete = (e: React.MouseEvent, tag: string) => {
        e.preventDefault()
        onDeleteTag(tag)
    } 

    return (
        <div className="field pb-1">
            <label className="label">
                <span>{fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1,fieldLabel.length)}</span>
                {isOptional && <OptionalFieldFormLabel />}
            </label>                                 

            {!isDisabled &&
                <div className="field has-addons">
                    <div className="control">
                        <input className={`input ${errorValue && errorValue.isError ? `is-danger`: ''}`} type="text" name={fieldName} value={tagInputValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTagInputValue(e.target.value)} />
                        { errorValue && errorValue.isError && <p className="help is-danger">{errorValue.errorMessage}</p> }
                    </div>
                        <div className="control">
                            <button className="button is-info is-light is-outlined" onClick={onAdd}>
                                Add
                            </button>
                        </div>
                </div>
            }

            <div className="field is-grouped is-grouped-multiline">
                {
                tags.length > 0
                ?
                tags.map((tag, idx) => (
                    <div className="control" key={idx}>
                        <div className="tags has-addons">
                            <span className="tag is-light">{tag}</span>
                            {!isDisabled && <span className="tag is-delete" onClick={(e: React.MouseEvent) => onDelete(e, tag)}></span> }
                        </div>
                    </div>
                )) 
                : 
                <span className="is-italic is-size-6">{`None`}</span>
                }
            </div>
        </div>
    )
}

export { TagsInput }