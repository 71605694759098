import React, { useEffect, useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import { faBoltLightning, faCartShopping, faWarehouse, faCircleUser,  faListCheck,  faPlus, faStore } from '@fortawesome/free-solid-svg-icons'
import { User } from 'ManageUsersScreen'
import { Permissions } from 'AppController'
import { isMerchant } from 'models/User'
import { ManageProductCounts, useManagedProductCounts } from 'hooks/useManagedProductCounts'
import { BasketContext } from './contexts/Basket'
import { Basket } from 'pages/orders/Basket'



const NavBar = ({ loggedInUser, manageProductCounts }: {loggedInUser: User, manageProductCounts: ManageProductCounts}) => {

    const { logout } = useAuth0()

    const { clearBasket} = useContext(BasketContext)

    useEffect(() => { 
        // @ts-ignore
        Canny('initChangelog', {
          appID: '64ea275c8ed23409769842e7',
          position: 'bottom',
          align: 'right',
          theme: 'light', // options: light [default], dark, auto
       });
    }, [])
  
    return(
      <nav className="navbar is-fixed-top" style={{background: '#f9f9f9'}}>
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to={`/`}>
              <span className="subtitle is-3">cadly</span>
            </Link>
            <div className="navbar-burger" data-target="main-navbar">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
  
          <div id="main-navbar" className="navbar-menu">
            <div className="navbar-end">

            {Permissions.canViewOrders(loggedInUser) &&
              <Link to={`/manage/orders`} state={null} className="navbar-item is-hoverable">
                <FontAwesomeIcon icon={faStore} className={`pr-2`} />
                  Orders
              </Link>
            }
            {Permissions.canViewWorkshopSchedule(loggedInUser) &&
                <Link to={`/manage/workshop/scheduled`} state={null} className="navbar-item">
                  <FontAwesomeIcon icon={faWarehouse} className={`pr-2`} />
                  Manage Workshop
                </Link>
            }

            {Permissions.canViewManaged(loggedInUser) &&
                <Link to={`/manage/products`} state={null} className="navbar-item">
                  <FontAwesomeIcon icon={faListCheck} className={`pr-2`} />
                  Manage Products {(manageProductCounts?.editsRequested + manageProductCounts?.pendingReview > 0) && <span style={{fontSize: '0.6em'}} className="ml-1 mb-3 tag is-rounded is-danger has-text-weight-bold">{manageProductCounts.editsRequested + manageProductCounts.pendingReview}</span> }
                </Link>
            }
              
            {Permissions.canAdd(loggedInUser) &&
              <div className="navbar-item has-dropdown is-hoverable">
                  <div className="navbar-link">
                      <FontAwesomeIcon icon={faPlus} className={`pr-2`} />
                      <span>Add</span>
                  </div>
                  <div className="navbar-dropdown is-boxed">
                    <Link to={`/add/product`} state={null} className="navbar-item">
                      Product
                    </Link>
                    <hr className="navbar-divider" />
                    <Link to={`/add/drawing`} className="navbar-item">
                      Drawing
                    </Link>
                  </div>
              </div>
              }

              {Permissions.canCreateOrder(loggedInUser) &&
                <div className="navbar-item has-dropdown is-hoverable">
                    <div className="navbar-link">
                        <FontAwesomeIcon icon={faCartShopping} className={`pr-2`} />
                        <span>Basket</span>
                    </div>
                    <div className="navbar-dropdown is-boxed is-right" style={{minWidth: '600px'}}>

                        <div className="dropdown-item">

                          <Basket>
                            <div className="field is-grouped is-pulled-right">
                              <div className="control">
                                <Link to={`/order/create`}>
                                  <button className={`button is-link`}>
                                      Checkout
                                  </button>
                                </Link>
                              </div>
                              <div className="control">
                                  <button className="button is-link is-light" onClick={() => {clearBasket()}}>Clear Basket</button>
                              </div>
                            </div>  
                          </Basket>
        
                        </div>

                        </div>
                      
                </div>
              }

              <div className="navbar-item has-dropdown is-hoverable">
                  <div className="navbar-link" >
                    <FontAwesomeIcon icon={faCircleUser} size={'lg'} />
                    <span className="pl-1 has-text-weight-semibold">{loggedInUser.name}</span>
                  </div>
                  <div className="navbar-dropdown is-right is-boxed">
                    <div className="navbar-item" >
                      <span className="has-text-weight-semibold">Company: {isMerchant(loggedInUser) ? loggedInUser.merchant && loggedInUser.merchant.name : loggedInUser.organisation && loggedInUser.organisation.name}</span>
                    </div>
                    <hr className="navbar-divider" />
                    {Permissions.canViewManageUsers(loggedInUser) &&
                      <>
                        <Link to={`/manage-users`} className="navbar-item">
                          Manage Users
                        </Link>
                        <hr className="navbar-divider" />
                      </>
                    }
                    {Permissions.canViewSettings(loggedInUser) &&
                      <>
                        <Link to={`/settings`} className="navbar-item">
                          Settings
                        </Link>
                        <hr className="navbar-divider" />
                      </>
                    }
                    {isMerchant(loggedInUser) &&
                      <>
                        <Link to={`/my-orders`} className="navbar-item">
                          My Orders
                        </Link>
                        <hr className="navbar-divider" />
                      </>
                    }
                    <a className="navbar-item" href="https://cadly.canny.io/feature-requests" target="_blank">
                      Send Feedback
                    </a>
                    <hr className="navbar-divider" />
                    <a className="navbar-item" onClick={(e: React.MouseEvent) => { e.preventDefault(); logout({ returnTo: window.location.origin })} }>
                      Logout
                    </a>
                  </div>
              </div>

              {!isMerchant(loggedInUser) &&
                <div className="navbar-item is-clickable">
                  <button className="button is-small is-primary is-light" data-canny-changelog>
                      <FontAwesomeIcon icon={faBoltLightning} className={`pr-2`} />
                      <span>See What's New</span>
                  </button>
                </div>
              }

            </div>
          </div>
        </div>
      </nav>
    )
  }

  export { NavBar }