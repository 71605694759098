import React from 'react'

import { useParams } from 'react-router-dom'
import { User } from '@auth0/auth0-react'
import { IOrderResponse, useOrder } from 'hooks/useOrder'

// @ts-ignore
import OrderCompleteImage2 from '/public/images/undraw_awesome_order_complete.svg'

// @ts-ignore
import OrderCompleteImage3 from '/public/images/undraw_celebration_order_complete.svg'

import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { DATE_FORMAT, DATE_TIME_FORMAT, prettyDisplayValue } from 'constants/Constants'
import moment from 'moment'
import { ReadOnlyBasket } from './Basket'
import { BasketItem } from 'contexts/Basket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarAlt, faTruck, faUser } from '@fortawesome/free-solid-svg-icons'

const OrderDetailsPanel = ({ order }: { order: IOrderResponse} ) => {
    return(
        <div className="columns has-text-centered pt-4">

        <div className="column">
            <h2 className="subtitle is-5">
                <FontAwesomeIcon icon={faCalendarAlt} className="pr-2" />
                Order Details
            </h2>
            <p><span className="has-text-weight-semibold">Order Date:</span> {moment(order.orderDetails.timestamp, DATE_TIME_FORMAT).format(DATE_FORMAT)}</p>
            <p><span className="has-text-weight-semibold">Status:</span> {prettyDisplayValue(order.orderDetails.status)}</p>
        </div>

        <div className="column">
            <h2 className="subtitle is-5">
                <FontAwesomeIcon icon={faUser} className="pr-2" />
                Customer Details
            </h2>
            <p><span className="has-text-weight-semibold">Company Name:</span> {order.customerDetails.merchantName}</p>
        </div>

        <div className="column">
            <h2 className="subtitle is-5">
                <FontAwesomeIcon icon={faTruck} className="pr-2" />
                Delivery Details
            </h2>
            <p><span className="has-text-weight-semibold">Company Name:</span> {order.deliveryDetails.companyName}</p>
            <p><span className="has-text-weight-semibold">Post Code:</span> {order.deliveryDetails.postCode}</p>
            <p><span className="has-text-weight-semibold">Telephone Number:</span> {order.deliveryDetails.telephoneNumber}</p>
        </div>

    </div>
    )
}

const OrderSummaryScreen = ({ loggedInUser }: { loggedInUser: User }) => {

    const { orderId } = useParams<{orderId: string}>()
    const { order } = useOrder(orderId)

    return(
        <div className="container is-fullheight">

            <div className="columns mt-6 pt-6">
                <div className="column is-12 has-background-white" style={{padding: '5rem', borderRadius: 6}}>

                    {order 
                    ?
                    <>
                    <section className="hero is-medium mb-6 p-6 has-background-warning-light" style={{borderRadius: '30px'}}> 

                        <div className="hero-body pt-0 pb-6">
                            <div className="container has-text-centered">

                                <img className="pb-4" src={OrderCompleteImage3} alt="Sucess, order completed image!" width={'30%'} height={'30%'} />

                                <p className="subtitle is-4 pb-1">
                                    {`Hi ${loggedInUser.name.split(' ')[0]},`} 
                                </p>

                                <p className="title is-3">
                                    Your order is completed!
                                </p>

                                <span style={{height: '3em'}} className="tag is-warning is-rounded is-medium">{`Order ID:`} <span className="pl-2 has-text-weight-semibold">{`#${order.orderId}`}</span></span>

                            </div>
                        </div>

                        <OrderDetailsPanel order={order} />

                    </section>


                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-4">
                                Order Summary
                            </h1>   
                        </div>
                    </div>

                    <ReadOnlyBasket basketItems={order.products} />
                    
                    </>
                    :

                    <PageLoadSpinner />
                    }




                        {/* <div className="columns">
                            <div className="column">
                                <h1 className="title is-3 mb-6">Order Summary</h1>
                            </div>
                            <div className="column">
                                
                            </div>
                        </div> */}
                        

                </div>
            </div>
            
        </div>
    )
}

export { OrderSummaryScreen, OrderDetailsPanel }