import React, { ReactElement, useEffect, useState } from 'react'

import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowRightLong, faCheck, faCircleInfo, faCircleUser, faComment, faCommentAlt, faCopy, faDisplay, faEllipsisVertical, faFile, faHistory, faPaperPlane, faPen, faSearch, faTimes, faTimesCircle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'bulma-toast'
import moment from 'moment'

import { useProductForm } from 'hooks/useProductForm'
import { ProductForm } from 'components/forms/ProductForm'
import { useDesignDocumentsForm } from 'hooks/useDesignDocumentsForm'
import { usePipeOpenings } from 'hooks/usePipeOpenings'
import { usePipeMaterials } from 'hooks/usePipeMaterials'
import { useProductTypes } from 'hooks/useProductTypes'
import { User } from 'ManageUsersScreen'
import { FormModal } from 'components/modals/FormModal'
import { IDrawingSearchResponse, IProductSearchResponse, Metadata, MetadataStatusType, ProductSearchResult } from 'SearchScreen'
import { API_URL, DATE_TIME_FORMAT, ConfigValueItem, prettyDisplayValue } from 'constants/Constants'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { PipeOpeningConfig } from 'pages/settings/configuration/ManagePipeOpeningsScreen'
import { IndexProductDocument } from 'IndexProductScreen'
import { TextAreaInputField } from 'components/inputs/TextAreaInputField'
import { Permissions } from 'AppController'
import { isMerchant } from 'models/User'

import { SearchFilters, useProductFilters } from 'hooks/useProductFilters'
import { ProductFiltersMenu } from 'components/filters/ProductFiltersMenu'
import { ManageProductCounts, useManagedProductCounts } from 'hooks/useManagedProductCounts'

const ManageProductView = ({
  product,
  isMerchant,
  productTypes,
  pipeOpenings,
  pipeMaterials,
}: {
  product: ProductSearchResult,
  isMerchant: boolean,
  productTypes: ConfigValueItem[],
  pipeOpenings: PipeOpeningConfig[],
  pipeMaterials: ConfigValueItem[],
}) => {

  const productForm = useProductForm() 
  const designDocumentsForm = useDesignDocumentsForm() 

  useEffect(() => {
    productForm.loadFormState(product)
    designDocumentsForm.loadFormState(product.designDocuments)
  }, [product])

  return (
      <ProductForm
        isMerchant={isMerchant}
        formView={'view'}
        productForm={productForm}
        designDocumentsForm={designDocumentsForm}
        productTypes={productTypes}
        pipeOpenings={pipeOpenings}
        pipeMaterials={pipeMaterials} />
  )
}
export enum ActiveTab {
  PENDING_REVIEW, 
  EDITS_REQUESTED
}

const APPROVED: MetadataStatusType = 'APPROVED'
export const EDITS_REQUESTED: MetadataStatusType = 'EDITS_REQUESTED'
export const PENDING_REVIEW: MetadataStatusType = 'PENDING_REVIEW'

const initialRequestEditsForm = {comment: ''}

interface RequestEditsForm {
  comment?: string
}

const ManageProductsScreen = ({ 
  loggedInUser, 
  activeTab,
  manageProductCounts, // TODO - should this be a Context?
  refreshCounts,
}:{ 
  loggedInUser: User, 
  activeTab: ActiveTab,
  manageProductCounts: ManageProductCounts,
  refreshCounts: () => void
}) => {

    const { getAccessTokenSilently  } = useAuth0()

    const [didProductUpdate, setDidProductUpdate] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)
    const [isVersionHistoryModalActive, setIsVersionHistoryModalActive] = useState(false)
    const [requestEditsForm, setRequestEditsForm] = useState<RequestEditsForm>(initialRequestEditsForm)

    const { pipeOpenings, getPipeOpenings } = usePipeOpenings()
    const { pipeMaterials, getPipeMaterials } = usePipeMaterials()
    const { productTypes, getProductTypes } = useProductTypes()

    const [productSearchResponse, setProductSearchResponse] = useState<IProductSearchResponse>()

    const [isMarkAsApprovedLoading, setIsMarkAsApprovedLoading] = useState<boolean>(false)
    const [isRequestEditsLoading, setIsRequestEditsLoading] = useState<boolean>(false)
    const [isVersionHistoryLoading, setIsVersionHistoryLoading] = useState<boolean>(false)
    // TODO - can probably cache both of these.
    const [selectedProduct, setSelectedProduct] = useState<ProductSearchResult>(null)
    const [productVersions, setProductVersions] = useState<ProductSearchResult[]>(null)

    const { productFilters, onCheckFilter: onCheckProductFilter, onCheckNoneFilter, onClearFilters } = useProductFilters()

    const [searchQuery, setSearchQuery] = useState<string>('')

    // TODO - make a hook - repeated in manageProducts
    const search = async (currentPage: number, searchQuery: string, filters?: SearchFilters) => {

      const accessToken: string = await getAccessTokenSilently()

      const pageParam: string = currentPage ? `&page=${currentPage}` : ``

      const pendingReviewFilter = `&metadata.status=${PENDING_REVIEW}`
      const editsRequestedFilter = `&metadata.status=${EDITS_REQUESTED}`

      const statusFilter: string = activeTab === ActiveTab.PENDING_REVIEW ? pendingReviewFilter : activeTab === ActiveTab.EDITS_REQUESTED ? editsRequestedFilter : ``

      if (filters) {
  
        const steelworkCodeFilters: string = filters && filters.steelworkCode && filters.steelworkCode.appliedFilters.length > 0  ? `&steelworkCode=${filters.steelworkCode.appliedFilters.join(',')}` : ''
        const handrailFilters: string = filters && filters.handrail && filters.handrail.appliedFilters.length > 0  ? `&handrail=${filters.handrail.appliedFilters.join(',')}` : ''
        const productTypeFilters: string = filters && filters.productType && filters.productType.appliedFilters.length > 0  ? `&productType=${filters.productType.appliedFilters.join(',')}` : ''
        const toeFilters: string = filters && filters.toe && filters.toe.appliedFilters.length > 0  ? `&toe=${filters.toe.appliedFilters.join(',')}` : ''
        const secondaryCastingFilters: string = filters && filters.secondaryCasting && filters.secondaryCasting.appliedFilters.length > 0  ? `&secondaryCasting=${filters.secondaryCasting.appliedFilters.join(',')}` : ''
       
        const flowControlDeviceSizeFilters: string = filters && filters.flowControlDeviceSize && filters.flowControlDeviceSize.appliedFilters.length > 0  ? `&flowControlDevices.size=${filters.flowControlDeviceSize.appliedFilters.join(',')}` : ''
        const flowControlDeviceTypeFilters: string = filters && filters.flowControlDeviceType && filters.flowControlDeviceType.appliedFilters.length > 0  ? `&flowControlDevices.type=${filters.flowControlDeviceType.appliedFilters.join(',')}` : ''
  
        const invertLevelFilters: string = filters && filters.invertLevel && filters.invertLevel.appliedFilters.length > 0  ? `&pipeOpenings.invertLevel=${filters.invertLevel.appliedFilters.join(',')}` : ''
        const openingLevelFilters: string = filters && filters.openingLevel && filters.openingLevel.appliedFilters.length > 0  ? `&pipeOpenings.openingLevel=${filters.openingLevel.appliedFilters.join(',')}` : ''
        const insideDiameterFilters: string = filters && filters.insideDiameter && filters.insideDiameter.appliedFilters.length > 0  ? `&pipeOpenings.insideDiameter=${filters.insideDiameter.appliedFilters.join(',')}` : ''
        const pipeMaterialFilters: string = filters && filters.pipeMaterial && filters.pipeMaterial.appliedFilters.length > 0  ? `&pipeOpenings.material=${filters.pipeMaterial.appliedFilters.join(',')}` : ''
  
        const hasHandrailFilter: string = filters?.handrail?.hasNoneSelected ? `&hasHandrail=false` : ``
        const hasSteelworkCodeFilter: string = filters?.steelworkCode?.hasNoneSelected ? `&hasSteelwork=false` : ``
        const hasToeFilter: string = filters?.toe?.hasNoneSelected ? `&hasToe=false` : ``
        const hasSecondaryCastingFilter: string = filters?.secondaryCasting?.hasNoneSelected ? `&hasSecondaryCasting=false` : ``
        const hasFlowControlDeviceFilter: string = (filters?.flowControlDeviceSize?.hasNoneSelected || filters?.flowControlDeviceType?.hasNoneSelected) ? `&hasFlowControlDevice=false` : ``
        const hasPipeOpeningFilter: string = (filters?.invertLevel?.hasNoneSelected || filters?.openingLevel?.hasNoneSelected || filters?.insideDiameter?.hasNoneSelected || filters?.pipeMaterial?.hasNoneSelected) ? `&hasPipeOpenings=false` : ``
         
        const response = await fetch(
          `${API_URL}/products/search?q=${searchQuery}${statusFilter}${pageParam}${steelworkCodeFilters}${handrailFilters}${productTypeFilters}${insideDiameterFilters}${pipeMaterialFilters}${toeFilters}${flowControlDeviceSizeFilters}${flowControlDeviceTypeFilters}${secondaryCastingFilters}${invertLevelFilters}${openingLevelFilters}${hasHandrailFilter}${hasSteelworkCodeFilter}${hasToeFilter}${hasSecondaryCastingFilter}${hasFlowControlDeviceFilter}${hasPipeOpeningFilter}`
            , {
              method: 'GET',
              redirect: 'follow',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            }
            })
  
            const searchResponse: IProductSearchResponse = await response.json()
            setProductSearchResponse(searchResponse)     
            setSelectedProduct(searchResponse.results[0] || null)
            setDidProductUpdate(false)
        
      } else {

        const response = await fetch(
          `${API_URL}/products/search?${statusFilter}${pageParam}`
            , {
              method: 'GET',
              redirect: 'follow',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": 'application/json'
            }
            })
  
            const searchResponse: IProductSearchResponse = await response.json()

            setProductSearchResponse(searchResponse)     
            setSelectedProduct(searchResponse.results[0] || null)
            setDidProductUpdate(false)
      }

    }

    const getProductVersions = async () => {

      setIsVersionHistoryLoading(true)

      const accessToken: string = await getAccessTokenSilently()

      const response = await fetch(
      `${API_URL}/products/get/${selectedProduct.objectID}/versions`
          , {
          method: 'GET',
          redirect: 'follow',
          headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": 'application/json'
          },
          }
      )
  
      const productVersionsResp: ProductSearchResult[] = await response.json()
      setProductVersions(productVersionsResp)
      setIsVersionHistoryLoading(false)
    }

    // TODO - move the useEffect into the hook
    useEffect(() => {      
      getProductTypes()
    }, [])

    // TODO - move the useEffect into the hook
    useEffect(() => {      
          getPipeOpenings()
    }, [])

    // TODO - move the useEffect into the hook
    useEffect(() => {
          getPipeMaterials()
    }, [])
    

    useEffect(() => {

            // delay fetching to allow time for indexing to happen on BE
            // TODO - handle properly with loading state?
            const timeoutId = setTimeout(() => {
          
              search(0, searchQuery, productFilters)
              .catch((error) => console.log(error))
      
              refreshCounts()
              
            }, 3000);
      
            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeoutId);

      }, [didProductUpdate, productFilters])

      useEffect(() => {
        search(0, null) // when active tab changes we want no product filters to be applied.
        .catch((error) => console.log(error))
      }, [activeTab])

      const onClickMarkAsApproved = async (e: React.MouseEvent) => {
        e.preventDefault()

          setIsMarkAsApprovedLoading(true)

          const indexDoc: IndexProductDocument = {
            ...selectedProduct,
            metadata: {
              ...selectedProduct.metadata,
              status: APPROVED,
              lastModerated: {
                byUserId: loggedInUser.user_id,
                byFullName: loggedInUser.name,
                timestamp: moment.utc().format(DATE_TIME_FORMAT)
              }
            }
          }

          const accessToken: string = await getAccessTokenSilently()

          const response = await fetch(
              `${API_URL}/products/index`
                  , {
                  method: 'POST',
                  redirect: 'follow',
                  headers: {
                      Authorization: `Bearer ${accessToken}`,
                      "Content-Type": 'application/json'
                  },
                  body: JSON.stringify(indexDoc)
              }); 

          if (response.status === 201) {
              toast({
                  message: `Success`,
                  type: 'is-link',
                  position: 'bottom-right',
                })

                setIsMarkAsApprovedLoading(false)
                setDidProductUpdate(true)

          } else {
              toast({
                  message: 'Oh no, something went wrong. Please try again.',
                  type: 'is-danger',
                  position: 'bottom-right',
                })

                setIsMarkAsApprovedLoading(false)
          }
      }

      const onClickMarkAsEditsRequested = async (e: React.MouseEvent) => {
        e.preventDefault()
  
          setIsRequestEditsLoading(true)

          const indexDoc: IndexProductDocument = {
            ...selectedProduct,
            metadata: {
              ...selectedProduct.metadata,
              status: EDITS_REQUESTED,
              lastModerated: {
                byUserId: loggedInUser.user_id,
                byFullName: loggedInUser.name,
                timestamp: moment.utc().format(DATE_TIME_FORMAT),
                ...(requestEditsForm.comment && { comment: requestEditsForm.comment })
              }
            }
          }

          const accessToken: string = await getAccessTokenSilently()

          const response = await fetch(
              `${API_URL}/products/index`
                  , {
                  method: 'POST',
                  redirect: 'follow',
                  headers: {
                      Authorization: `Bearer ${accessToken}`,
                      "Content-Type": 'application/json'
                  },
                  body: JSON.stringify(indexDoc)
              }); 

          if (response.status === 201) {
              toast({
                  message: `Success`,
                  type: 'is-link',
                  position: 'bottom-right',
                })

                setIsRequestEditsLoading(false)
                setDidProductUpdate(true)
                setIsModalActive(false)
                setRequestEditsForm(initialRequestEditsForm)
                
          } else {
              toast({
                  message: 'Oh no, something went wrong. Please try again.',
                  type: 'is-danger',
                  position: 'bottom-right',
                })

                setIsRequestEditsLoading(false)
          }
      }

      const displayEditInfo = (metadata: Metadata) => {

        if (metadata.lastEdited) {
          return <span><FontAwesomeIcon icon={faCircleInfo} /> {`Last updated by ${metadata.lastEdited.byFullName} ${metadata.lastEdited.byFullName === loggedInUser.name ? `(You)` : ``}`}</span>
        }

        return <span><FontAwesomeIcon icon={faCircleInfo} /> {`Created by ${metadata.created ? metadata.created.byFullName : 'Unknown'} ${metadata.created.byFullName === loggedInUser.name ? `(You)` : ``}`} </span>
      }

    const onClickPage = (pageNo: number, isDisabled: boolean) => isDisabled ? null : search(pageNo, searchQuery, productFilters)

      if (!productTypes || !pipeOpenings || !pipeMaterials) return(
        <div className="container is-fullheight">
            <div className="columns mt-6 pt-6">
                <div className="column is-offset-one-quarter is-half" style={{padding: '5rem', borderRadius: 6}}>
                    <PageLoadSpinner />
                </div>
            </div>
        </div>
    )

    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setRequestEditsForm(initialRequestEditsForm)
        setIsModalActive(false)
    }

    const onClickShowVersionHistoryModal = () => {
      getProductVersions()
      setIsVersionHistoryModalActive(true)
    }

    const onClickSearch = () => {
      search(0, searchQuery, productFilters)
    }

    const onClearSearch = () => {
      setSearchQuery('')
      search(0, '', productFilters)
    }

    const StatusTag = ({status, className = ``} : {status: MetadataStatusType, className?: string }) => (
      <span
       className={
        `tag
        ${className}  
        ${status === PENDING_REVIEW 
        ? 'is-info'
        : status === EDITS_REQUESTED 
        ? 'is-warning'
        : 'is-primary'
      }`}>
        {prettyDisplayValue(status)}
    </span>
    )

    return (

    <div className="container is-fullheight">

          <div className={`modal ${isVersionHistoryModalActive ? `is-active`: ``}`}>
                  <div className="modal-background"></div>

                  <div className="modal-card box">

                    <div className="block">
                      <button className="delete is-pulled-right" onClick={() => setIsVersionHistoryModalActive(false)} aria-label="close"></button>
                    </div> 

                      <div className="modal-card-body p-5">

                          <div className="columns pl-0 pr-0 mb-0">
                              <div className="column">
                                  <h1 className="title is-4 mb-2">{`Version History`}</h1>
                              </div>
                          </div>

                          <div className="columns is-vcentered p-4">
                              <div className="column">
                                  {isVersionHistoryLoading
                                  ?
                                  <PageLoadSpinner />
                                  :
                                  productVersions && productVersions.length > 0
                                    ?
                                    <div className="columns">
                                      <div className="column">
                                        <div className="timeline">
                                          {productVersions.map((pv) => {

                                            return (
                                              <div key={`${pv.objectID}-${pv.metadata.version}`}>
                                              <header className="timeline-header">
                                                {pv.metadata.version === 0 && pv.metadata.status === PENDING_REVIEW
                                                  ?
                                                  <span className={`tag is-primary`}>{`Created`}</span>
                                                  :
                                                  <StatusTag status={pv.metadata.status} />
                                                  }
                                              </header>
                                              

                                              <div className="timeline-item" key={`${pv.objectID}-${pv.metadata.version}`}>
                                                <div className="timeline-marker is-icon">
                                                  <FontAwesomeIcon icon={faArrowDown} />
                                                </div>
                                                <div className="timeline-content">
                                                  <p className="heading">
                                                    {pv.metadata.version === 0
                                                    ?
                                                    pv.metadata.created && <span>{pv.metadata.created.timestamp}</span>
                                                    :
                                                    pv.metadata.status === PENDING_REVIEW
                                                    ?
                                                    pv.metadata.lastEdited && <span>{pv.metadata.lastEdited.timestamp}</span>
                                                    :
                                                    pv.metadata.status === EDITS_REQUESTED || pv.metadata.status === APPROVED 
                                                    ?
                                                    pv.metadata.lastModerated && <span>{pv.metadata.lastModerated.timestamp}</span>
                                                    :
                                                    ''
                                                    }
                                                  </p>
                                                  
                                                  <div className="columns"> 
                                                    <div className="column">  

                                                      {pv.metadata.version === 0
                                                      ?
                                                      pv.metadata.created &&
                                                      <div className="columns is-vcentered">
                                                        <div className="column is-narrow p-0">
                                                          <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                                        </div>
                                                        <div className="column is-narrow">
                                                          {pv.metadata.created && <span>{`${pv.metadata.created.byFullName} ${pv.metadata.created.byFullName === loggedInUser.name ? `(You)` : ``}`}</span>}
                                                        </div>
                                                      </div>
                                                      :
                                                      pv.metadata.status === PENDING_REVIEW
                                                      ?
                                                      <div className="columns is-vcentered">
                                                        <div className="column is-narrow p-0">
                                                          <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                                        </div>
                                                        <div className="column is-narrow">
                                                          {pv.metadata.lastEdited && <span>{`${pv.metadata.lastEdited.byFullName} ${pv.metadata.lastEdited && pv.metadata.lastEdited.byFullName === loggedInUser.name ? `(You)` : ``} made edits`}</span>}
                                                        </div>
                                                      </div>
                                                      :
                                                      pv.metadata.status === EDITS_REQUESTED 
                                                      ?
                                                      <div className="columns is-vcentered">
                                                        <div className="column is-narrow p-0">
                                                          <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                                        </div>
                                                        <div className="column is-narrow">
                                                          {pv.metadata.lastModerated && <span>{`${pv.metadata.lastModerated.byFullName} ${pv.metadata.lastModerated.byFullName === loggedInUser.name ? `(You)` : ``} reviewed and requested edits`}</span>}
                                                        </div>
                                                      </div>
                                                      :
                                                      pv.metadata.status === APPROVED 
                                                      ?
                                                      <div className="columns is-vcentered">
                                                        <div className="column is-narrow p-0">
                                                          <FontAwesomeIcon icon={faCircleUser} size={'1x'} />
                                                        </div>
                                                        <div className="column is-narrow">
                                                          {pv.metadata.lastModerated && <span>{`${pv.metadata.lastModerated.byFullName} ${pv.metadata.lastModerated.byFullName === loggedInUser.name ? `(You)` : ``} reviewed and approved`}</span>}
                                                        </div>
                                                      </div>
                                                      :
                                                      ''
                                                      }

                                                    </div> 
                                                  </div>

                                                  <div className="columns"> 
                                                    <div className="column pt-0">  
                                                      {pv.metadata.status === PENDING_REVIEW
                                                        ?
                                                        pv.metadata.lastEdited && pv.metadata.lastEdited.comment && 
                                                        <article className="message is-light">
                                                          <div className="message-body">
                                                            <p className="pl-3">"{pv.metadata.lastEdited.comment}"</p>
                                                          </div>
                                                        </article>
                                                        :
                                                        pv.metadata.status === EDITS_REQUESTED || pv.metadata.status === APPROVED 
                                                        ?
                                                        pv.metadata.lastModerated && pv.metadata.lastModerated.comment && 
                                                        <article className="message is-light">
                                                          <div className="message-body">
                                                            <p className="pl-3">"{pv.metadata.lastModerated.comment}"</p>
                                                          </div>
                                                        </article>
                                                        :
                                                        ''
                                                      }
                                                    </div> 
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                            )}
                                            )}
                                            <div className="timeline-header">
                                              <span className="tag is-primary">Current</span>
                                            </div>
                                        </div>
                                      </div>
                                      </div>
                                    :
                                    <p>There is no version history for this product.</p>
                                  }
                              </div>
                          </div>
                          </div>
                      </div>
              </div>

              <FormModal
                isModalActive={isModalActive}
                modalTitle={`Request Edits`}
                onCancelModal={onCancelModal}
                Form={
                    <form>

                        <TextAreaInputField
                            fieldLabel={`Add a comment`}
                            onChange={(e) => setRequestEditsForm({comment: e.target.value})}
                            fieldName={'comment'}
                            value={requestEditsForm.comment}
                            errorValue={null}
                            isOptional={true}
                            helpText={`Request edits comment.`} />

                        <div className="field is-grouped is-pulled-right">
                            <div className="control">
                                <button className={`button is-link ${isRequestEditsLoading ? `is-loading` : ``}`} onClick={onClickMarkAsEditsRequested}>Confirm</button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={(e) => onCancelModal(e)}>Cancel</button>
                            </div>
                        </div>

                    </form>
                } />

        <div className="columns mt-6 pt-6">
            <div className="column is-12 has-background-white" style={{padding: '5rem', borderRadius: 6}}>

                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3 mb-6">Manage Products</h1>
                    </div>
                </div>

                {Object.values(productFilters).some(_ => _.appliedFilters.length > 0 || _.hasNoneSelected) &&
                  <div className="column is-narrow pl-0">
                    <button className="button is-text" aria-haspopup="true" aria-controls="dropdown-menu" onClick={onClearFilters}>
                      <span>Clear filters <FontAwesomeIcon className='pl-2' icon={faTimes} /></span>
                    </button>
                  </div>
                }

                <ProductFiltersMenu
                  productSearchResponse={productSearchResponse}
                  productFilters={productFilters}
                  onCheckProductFilter={onCheckProductFilter}
                  onCheckNoneFilter={onCheckNoneFilter}
                  menuSize={'small'} />

                <div className="tabs is-small">
                  <ul>
                    <li onClick={onClearFilters} className={`${activeTab === ActiveTab.PENDING_REVIEW ? `is-active` : ``}`}><Link to={`/manage/products`}>Pending Review <span style={{fontSize: '0.8em'}} className="ml-1 tag is-rounded has-text-weight-bold">{manageProductCounts?.pendingReview}</span> </Link></li>
                    <li onClick={onClearFilters} className={`${activeTab === ActiveTab.EDITS_REQUESTED ? `is-active` : ``}`}><Link to={`/manage/products/edits`}>Edits Requested <span style={{fontSize: '0.8em'}} className="ml-1 tag is-rounded has-text-weight-bold">{manageProductCounts?.editsRequested}</span> </Link></li>
                  </ul>
                </div>

                <div className="columns">
                    <div className="column is-3 pt-1" style={{borderRight: 'rgb(228, 228, 228) 1px solid'}}>

                        <div className="field has-addons pb-3">
                          <div className="control has-icons-right is-expanded">
                            <input 
                              className="input is-small" 
                              type="text" 
                              onChange={(e) => setSearchQuery(e.currentTarget.value)} 
                              onKeyPress={event => event.key === 'Enter' && onClickSearch()} 
                              value={searchQuery} 
                              disabled={productSearchResponse?.results?.length === 0 && searchQuery === ''}
                              autoFocus={true} />

                            {searchQuery !== '' &&
                              <span className="icon is-right is-clickable" onClick={onClearSearch}>
                                <FontAwesomeIcon icon={faXmark} />
                              </span>
                            }
                          </div>
                          <div className="control">
                            <button disabled={productSearchResponse?.results?.length === 0 && searchQuery === ''} className={`button is-link is-light is-outlined is-small`} onClick={onClickSearch}>
                              <FontAwesomeIcon icon={faSearch} />
                            </button>
                          </div>
                        </div>

                        {productSearchResponse && productSearchResponse.results.map((result) => (
                            <div className={`columns p-1 is-clickable ${result.objectID === (selectedProduct && selectedProduct.objectID) ? `has-background-light` : `hoverable-item`}`} key={result.objectID}>
                               <div className="column" onClick={() => setSelectedProduct(result)}>
                                <div className="columns mb-2">
                                  <div className="column p-0 pt-1 pr-1">
                                    <StatusTag status={result.metadata.status} className={`is-pulled-right`} />
                                  </div>
                                  <div className="column p-0 pt-1 pr-1 is-narrow is-pulled-right">
                                    <div className="dropdown is-hoverable">
                                    <div className="dropdown-trigger pl-3 pr-1 is-clickable">
                                        <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu-product-actions" icon={faEllipsisVertical} size={`lg`} />
                                    </div>
                                        <div className="dropdown-menu" id="dropdown-menu-product-actions" role="menu">
                                          <div className="dropdown-content">
                                            <button onClick={onClickShowVersionHistoryModal} className={`button is-small is-text dropdown-item`}> <FontAwesomeIcon icon={faHistory} className="pr-2"/> {`Version History`}</button>
                                            
                                            {Permissions.canAdd(loggedInUser) && 
                                              <>
                                                <hr className="navbar-divider"/>
                                                <Link to={`/add/product`} state={{ duplicateState: selectedProduct }} className="button is-small is-text dropdown-item">{<span><FontAwesomeIcon className="pr-2" icon={faCopy} />{`Duplicate`}</span>}</Link> 
                                              </> 
                                            }
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div className="columns">
                                  <div className="column is-narrow">
                                    <FontAwesomeIcon icon={faFile} size='2x' />        
                                  </div>
                                  <div className="column" onClick={() => setSelectedProduct(result)}>
                                      <p>
                                      <span className="has-text-weight-semibold title is-6" >
                                          {result.productCode}
                                      </span>
                                      </p>
                                      <p className="subtitle is-7">{displayEditInfo(result.metadata)}</p> 
                                  </div>
                                </div>
                            </div>
                            </div>
                        ))}  

                        {productSearchResponse && 
                          <nav className="pagination is-small" role="navigation" aria-label="pagination">
                            <a className={`pagination-previous ${productSearchResponse.noOfResults === 0 || productSearchResponse.page === 0 ? `is-disabled` : ``}`} title="This is the first page" onClick={() => onClickPage(productSearchResponse.page - 1, productSearchResponse.page === 0)}>Previous</a>
                            <a className={`pagination-next ${productSearchResponse.noOfResults === 0 || (productSearchResponse.page === productSearchResponse.pages - 1) ? `is-disabled` : ``}`} onClick={() => onClickPage(productSearchResponse.page + 1, productSearchResponse.page === productSearchResponse.pages - 1)}>Next page</a>
                          </nav>
                        }
                    </div>
                    <div className="column pr-6 pb-6 pl-6 pt-1">
                      {productSearchResponse && productSearchResponse.noOfResults > 0 && selectedProduct
                      ? 
                      <>

                      {activeTab === ActiveTab.EDITS_REQUESTED && selectedProduct.metadata.status === EDITS_REQUESTED &&
                      <div className="message is-dark">
                        <div className="message-body p-5">
                          <div className="columns is-vcentered">
                            <div className="column is-narrow p-0">
                              <FontAwesomeIcon icon={faCircleUser} size={'2x'} />
                            </div>
                            <div className="column is-narrow">
                              <span className="pl-1 has-text-weight-semibold">{selectedProduct.metadata.lastModerated.byFullName}</span> has requested edits
                            </div>
                            <div className="column">
                              <Link to={`/edit/product/${selectedProduct.productCode}`} state={{from: EDITS_REQUESTED}}>
                                <button className="button is-warning is-rounded is-pulled-right">Edit</button>
                              </Link>
                            </div>
                        </div>
                        <div className="columns is-vcentered">
                          <div className="column pt-0 pl-6">
                            {selectedProduct.metadata.lastModerated.comment && 
                            <>
                            <span className="has-text-weight-bold is-size-4">"</span>
                            <span className="is-italic is-size-5">{selectedProduct.metadata.lastModerated.comment}</span>
                            <span className="has-text-weight-bold is-size-4">"</span>
                            </>
                            }
                          </div>
                        </div>
                        </div>
                      </div>
                      }

                      {activeTab === ActiveTab.PENDING_REVIEW && 
                       selectedProduct.metadata.status === PENDING_REVIEW &&
                       selectedProduct.metadata.lastEdited.comment &&
                        <div className="columns">
                          <div className="column">
                            <div className="message is-info">
                              <div className="message-body p-5">
                                <div className="columns is-vcentered">
                                  <div className="column is-narrow p-0">
                                    <FontAwesomeIcon icon={faCircleUser} size={'2x'} />
                                  </div>
                                  <div className="column is-narrow">
                                    <span className="pl-1 has-text-weight-semibold">{`${selectedProduct.metadata.lastEdited.byFullName} ${selectedProduct.metadata.lastEdited.byFullName === loggedInUser.name ? `(You)` : ``}`}</span> has made edits
                                  </div>
                              </div>
                              <div className="columns is-vcentered">
                                <div className="column pt-0 pl-6">
                                  {selectedProduct.metadata.lastEdited.comment && 
                                  <>
                                  <span className="has-text-weight-bold is-size-4">"</span>
                                  <span className="is-italic is-size-5">{selectedProduct.metadata.lastEdited.comment}</span>
                                  <span className="has-text-weight-bold is-size-4">"</span>
                                  </>
                                  }
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      {activeTab === ActiveTab.PENDING_REVIEW && selectedProduct.metadata.status === PENDING_REVIEW &&
                          <div className="columns">
                            <div className="column">
                                <div className="field is-grouped is-pulled-right">
                                  {selectedProduct.metadata.lastEdited.byUserId !== loggedInUser.user_id && loggedInUser.role === 'ADMIN'
                                  ?
                                  <>
                                    <div className="control">
                                        <button className={`button is-small is-primary is-light is-outlined ${isMarkAsApprovedLoading ? `is-loading` : ``}`}onClick={onClickMarkAsApproved}>{`Approve`}<FontAwesomeIcon icon={faCheck} className="pl-2" /></button>
                                    </div>
                                    <div className="control">
                                        <button className={`button is-small is-danger is-light is-outlined ${isRequestEditsLoading ? `is-loading` : ``}`} onClick={() => setIsModalActive(true)}>{`Request Edits`}<FontAwesomeIcon icon={faXmark} className="pl-2" /></button>
                                    </div>
                                  </>
                                  :
                                  <>
                                  <div className="control">
                                    <Link to={`/edit/product/${selectedProduct.productCode}`}>
                                      <button className={`button is-small is-link is-light is-outlined`}>{`Edit`}<FontAwesomeIcon icon={faPen} className="pl-2" /></button>
                                    </Link>
                                  </div>
                                  </>
                                  }
                                </div>
                              </div>
                          </div>
                      }

                      <ManageProductView 
                        product={selectedProduct}
                        isMerchant={isMerchant(loggedInUser)} 
                        pipeMaterials={pipeMaterials}
                        pipeOpenings={pipeOpenings}
                        productTypes={productTypes} />
                      </>
                      :
                      productSearchResponse && productSearchResponse.noOfResults == 0
                      ?
                      <div className="has-text-centered">
                        {activeTab === ActiveTab.EDITS_REQUESTED
                        ?
                        <p className="title is-5 has-text-centered">{`There are no edits requested.`}</p>
                        :
                        <p className="title is-5 has-text-centered">{`There are no products for review.`}</p>
                        }
                      </div>
                      :
                      <PageLoadSpinner />
                      }

                    </div>
                </div>


            </div>
        </div>

    </div>
    )

}

export { ManageProductsScreen }